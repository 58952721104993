import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { track } from '@amplitude/analytics-browser';
import {
  Alert,
  Box,
  Button,
  Link,
  Text,
  Title,
  useToast,
} from '@nimbus-ds/components';
import { RedoIcon } from '@nimbus-ds/icons';
import { Layout, Page } from '@nimbus-ds/patterns';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import nexo from 'App/nexoClient';
import { ROUTES } from 'App/Routes/constants';
import { Roles } from 'commons/components/Roles/Roles.enum';
import ShowWithRole from 'commons/components/Roles/ShowWithRoles';
import { useIsMobile } from 'commons/hooks';
import { transactionsService } from 'commons/services';
import {
  ChargesDashboardStatus,
  FailureReason,
} from 'commons/services/dashboardServiceV2/dashboard.service';
import { getStatusTagCode } from 'commons/services/dashboardServiceV2/status-tag.service';
import { TransactionDetailsType } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';
import MobileTopNavBar from 'domains/Brazil/commons/components/MobileTopNavBar';
import NuvemPagoCta from 'domains/Brazil/commons/components/NuvemPagoCta';
import { TypeTag } from 'domains/Brazil/commons/components/StatusTag';
import {
  isCreditCard,
  isPix,
  isRefundablePaymentMethod,
} from 'domains/Brazil/commons/utils/paymentMethod';
import { isRefundableStatus } from 'domains/Brazil/commons/utils/status';
import {
  NotificationBRContext,
  NotificationType,
} from 'domains/Brazil/Notifications/Notification.context';
import { AlertRefund } from 'domains/Brazil/Refund/components/AlertRefund/AlertRefund';
import CustomerCard from './components/CustomerCard';
import HeaderBox from './components/HeaderBox';
import PaymentCard from './components/PaymentCard';
import SummaryCard from './components/SummaryCard';

function getRefundDetailTrackingTag(
  paymentMethod: string,
  status: string,
): string | undefined {
  if (!isPix(paymentMethod) && !isCreditCard(paymentMethod)) return;

  const templateTag = `nuvem_pago_payment_${paymentMethod}_{status}_detail_refund_button_click`;

  switch (status) {
    case TypeTag.Paid:
      return templateTag.replace('{status}', 'approved');
    case TypeTag.PartiallyRefunded:
      return templateTag.replace('{status}', 'partial_refunded');
    default:
      return;
  }
}

interface CustomAlertProps {
  title: string;
  message: string;
  linkTitle?: string;
  linkUrl?: string;
  appearance?: React.ComponentProps<typeof Alert>['appearance'];
  testid: string;
}

interface RefundButtonProps {
  readonly label: string;
  readonly onClick: () => void;
  readonly as: 'button' | 'a';
}

function RefundButton({ label, onClick, as }: RefundButtonProps): JSX.Element {
  const element =
    as === 'a' ? (
      <Link
        textDecoration="none"
        onClick={onClick}
        data-testid="refundButtonMobile"
      >
        <Text color="neutral-textHigh">{label}</Text>
      </Link>
    ) : (
      <Button data-testid="refundButtonDesktop" onClick={onClick}>
        <RedoIcon size={16} />
        {label}
      </Button>
    );

  return <ShowWithRole role={Roles.REFUND}>{element}</ShowWithRole>;
}

export default function TransactionDetails(): JSX.Element {
  const { t: transactionT } = useTranslationWithPrefix('transactions.details');
  const { t: refundT } = useTranslationWithPrefix('refund');
  const { t: navT } = useTranslationWithPrefix('navigation');
  const { transactionId } = useParams<{ transactionId: string }>();
  const [details, setDetails] = useState<TransactionDetailsType | undefined>();
  const isMobile = useIsMobile();
  const { addToast } = useToast();
  const history = useHistory();
  const notificationContext = useContext(NotificationBRContext);
  const [showAlert, setShowAlert] = useState(false);
  const [customAlertProps, setCustomAlertProps] = useState<CustomAlertProps>({
    title: '',
    message: '',
    linkTitle: '',
    appearance: undefined,
    testid: '',
  });

  const handleConsumeRefundSuccessNotification = useCallback(
    (notificationId: string, partial?: boolean) => {
      if (!details) return;

      notificationContext.removeNotification(notificationId);

      const isPixPayment = isPix(details.paymentMethod);

      const tKeyPath = `alert.${partial ? 'partial-' : ''}success${
        isPixPayment ? '-pix' : ''
      }` as const;
      const testid = `Transaction-${partial ? 'Partial-' : ''}Refund${
        isPixPayment ? '-Pix' : ''
      }-Success-Alert` as const;

      setCustomAlertProps({
        testid,
        title: refundT(`${tKeyPath}.title`),
        message: refundT(`${tKeyPath}.message`),
        appearance: 'success',
      });
      setShowAlert(true);
    },
    [details, notificationContext, refundT],
  );

  const fetchDetails = useCallback(async () => {
    try {
      if (!details) {
        const txnData =
          await transactionsService.getTransactionDetails(transactionId);
        setDetails(txnData);
      }

      const refundSuccessNotifications =
        notificationContext.findNotificationByType(
          NotificationType.RefundSuccess,
        );
      const partialRefundSuccessNotifications =
        notificationContext.findNotificationByType(
          NotificationType.PartialRefundSuccess,
        );

      if (partialRefundSuccessNotifications.length) {
        handleConsumeRefundSuccessNotification(
          partialRefundSuccessNotifications[0].id,
          true,
        );
      } else if (refundSuccessNotifications.length) {
        handleConsumeRefundSuccessNotification(
          refundSuccessNotifications[0].id,
          false,
        );
      }
    } catch (error) {
      addToast({
        type: 'danger',
        text: transactionT('error.getDetailsFailure'),
        duration: 4000,
        id: 'transaction-details-failure-toast',
      });
    }
  }, [
    addToast,
    details,
    handleConsumeRefundSuccessNotification,
    notificationContext,
    transactionId,
    transactionT,
  ]);

  useEffect(() => {
    navigateHeader(nexo, {
      goTo: ROUTES.DASHBOARD,
      text: navT('back'),
    });

    fetchDetails();
  }, [fetchDetails, navT]);

  const statusTag = details
    ? getStatusTagCode(
        details.status as ChargesDashboardStatus,
        details.failureReason as FailureReason,
      )
    : TypeTag.NotDefined;

  const paddedOrderNumber =
    (details?.transactions?.at(0)?.orderNumber ?? -1) > 0
      ? String(details!.transactions[0].orderNumber).padStart(3, '0')
      : '---';

  const isRefundable =
    !!details &&
    isRefundablePaymentMethod(details.paymentMethod) &&
    isRefundableStatus(statusTag);

  const goToRefund = useCallback(() => {
    if (!details) return;

    const trackingTag = getRefundDetailTrackingTag(
      details.paymentMethod,
      statusTag,
    );

    if (trackingTag) track(trackingTag);

    history.push({
      pathname: `${ROUTES.ORDER_REFUND}/${details.order.id}`,
      search: '?v=2',
    });
  }, [details, history, statusTag]);

  const handleAlertCloseClick = useCallback(() => setShowAlert(false), []);

  if (!details) {
    return <TransactionDetailsSkeleton />;
  }

  return (
    <Box
      backgroundColor={isMobile ? 'neutral-background' : 'neutral-surface'}
      maxWidth="100vw"
      padding="none"
      margin="none"
      data-testid="transactionDetails"
    >
      <MobileTopNavBar backLinkPath={ROUTES.DASHBOARD}>
        {isRefundable && (
          <RefundButton as="a" label={refundT('refund')} onClick={goToRefund} />
        )}
      </MobileTopNavBar>
      <Page maxWidth="1092px">
        <Page.Header
          paddingX="4"
          paddingTop="4"
          paddingBottom="none"
          title={transactionT('header.title', {
            transactionNumber: paddedOrderNumber,
          })}
          buttonStack={
            !isMobile &&
            isRefundable && (
              <RefundButton
                as="button"
                label={refundT('refund')}
                onClick={goToRefund}
              />
            )
          }
        />
        <Page.Body paddingX="4">
          <HeaderBox
            details={details}
            extraContent={
              showAlert && (
                <AlertRefund
                  isShowAlert={showAlert}
                  title={customAlertProps.title}
                  message={customAlertProps.message}
                  linkTitle={customAlertProps.linkTitle}
                  linkUrl={customAlertProps?.linkUrl}
                  appearance={customAlertProps.appearance}
                  handleAlertCloseClick={handleAlertCloseClick}
                  testid={customAlertProps.testid}
                />
              )
            }
          />
          <Layout columns="2 - asymmetric" gap="5" padding="4" paddingX="none">
            <Layout.Section order="1" marginRight={isMobile ? 'none' : '3'}>
              <SummaryCard details={details} />
            </Layout.Section>
            <Layout.Section order={isMobile ? '3' : '2'}>
              <CustomerCard details={details} />
            </Layout.Section>
            <Layout.Section
              order={isMobile ? '2' : '3'}
              marginRight={isMobile ? 'none' : '3'}
            >
              <PaymentCard details={details} />
            </Layout.Section>
          </Layout>
          <Box>
            <NuvemPagoCta
              href={
                'https://atendimento.nuvemshop.com.br/pt_BR/gestao-dos-pagamentos/como-consultar-os-pagamentos-atraves-do-painel-nuvem-pago'
              }
              title={transactionT('cta')}
              trackingTag="nuvempago_link_detail_transaction_can_link_click"
            />
          </Box>
        </Page.Body>
      </Page>
    </Box>
  );
}

function TransactionDetailsSkeleton(): JSX.Element {
  const isMobile = useIsMobile();

  return (
    <Box
      backgroundColor={isMobile ? 'neutral-background' : 'neutral-surface'}
      maxWidth="100vw"
      padding="none"
      margin="none"
      data-testid="transactionDetailsSkeleton"
    >
      {isMobile && <MobileTopNavBar backLinkPath={ROUTES.DASHBOARD} />}
      <Page maxWidth="1092px">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="4"
          paddingTop="4"
          paddingBottom="none"
        >
          <Title.Skeleton width="208px" />
          {!isMobile && <Button.Skeleton width="145px" />}
        </Box>
        <Page.Body paddingX="4">
          <HeaderBox.Skeleton />
          <Layout columns="2 - asymmetric" gap="5" padding="4" paddingX="none">
            <Layout.Section order="1" marginRight={isMobile ? 'none' : '3'}>
              <SummaryCard.Skeleton />
            </Layout.Section>
            <Layout.Section order={isMobile ? '3' : '2'}>
              <CustomerCard.Skeleton />
            </Layout.Section>
            <Layout.Section
              order={isMobile ? '2' : '3'}
              marginRight={isMobile ? 'none' : '3'}
            >
              <PaymentCard.Skeleton />
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </Box>
  );
}

TransactionDetails.Skeleton = TransactionDetailsSkeleton;
