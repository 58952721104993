import React from 'react';

const DocumentIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 3.00195C0.89543 3.00195 0 3.89738 0 5.00195V15.002C0 16.1065 0.89543 17.002 2 17.002H18C19.1046 17.002 20 16.1065 20 15.002V5.00195C20 3.89738 19.1046 3.00195 18 3.00195H2ZM1.5 5.00195C1.5 4.72581 1.72386 4.50195 2 4.50195H18C18.2761 4.50195 18.5 4.72581 18.5 5.00195V15.002C18.5 15.2781 18.2761 15.502 18 15.502H2C1.72386 15.502 1.5 15.2781 1.5 15.002V5.00195ZM4.0625 6.25196C3.54473 6.25196 3.125 6.67169 3.125 7.18946V12.8145C3.125 13.3322 3.54473 13.752 4.0625 13.752H8.4375C8.95527 13.752 9.375 13.3322 9.375 12.8145V7.18946C9.375 6.67169 8.95527 6.25196 8.4375 6.25196H4.0625ZM4.375 12.502V7.50196H8.125V12.502H4.375ZM11.25 7.18946C11.25 7.01687 11.3899 6.87696 11.5625 6.87696H15.9375C16.1101 6.87696 16.25 7.01687 16.25 7.18946V7.81446C16.25 7.98705 16.1101 8.12696 15.9375 8.12696H11.5625C11.3899 8.12696 11.25 7.98705 11.25 7.81446V7.18946ZM11.5625 9.37696C11.3899 9.37696 11.25 9.51687 11.25 9.68946V10.3145C11.25 10.487 11.3899 10.627 11.5625 10.627H15.9375C16.1101 10.627 16.25 10.487 16.25 10.3145V9.68946C16.25 9.51687 16.1101 9.37696 15.9375 9.37696H11.5625ZM11.25 12.1895C11.25 12.0169 11.3899 11.877 11.5625 11.877H15.9375C16.1101 11.877 16.25 12.0169 16.25 12.1895V12.8145C16.25 12.987 16.1101 13.127 15.9375 13.127H11.5625C11.3899 13.127 11.25 12.987 11.25 12.8145V12.1895Z"
      fill="#0050C3"
    />
  </svg>
);

export default DocumentIcon;
