import React from 'react';
import { Alert, Text } from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';

export function AlertFileSizeError(): JSX.Element {
  const { t } = useTranslationWithPrefix(
    'banking.identityValidation.fileSizeError',
  );

  return (
    <Alert
      appearance="danger"
      title={t('title')}
      data-testid="alert-file-size-error"
    >
      <Text>{t('message')}</Text>
    </Alert>
  );
}
