import React from 'react';
import { useHistory } from 'react-router';
import { track } from '@amplitude/analytics-browser';
import { Box } from '@nimbus-ds/components';
import { ChevronRightIcon } from '@nimbus-ds/icons';
import { Country } from 'App/i18n/enums';
import { ROUTES } from 'App/Routes/constants';
import { useStoreInfo } from 'commons/hooks';
import { MoneyObjectInterface } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';
import { MoneyCard } from '../MoneyCard';

export function FutureIncomesCard(props: {
  amount: MoneyObjectInterface;
  skeleton: boolean;
  showLoadingDelay: boolean;
  hasError: boolean;
}): JSX.Element {
  const { t } = useTranslationWithPrefix('home');
  const { push } = useHistory();

  const { storeInfo } = useStoreInfo();

  const redirectToFutureIncome = () => {
    track('pago-nube-futuros-ingresos-card');
    push(ROUTES.FUTURE_INCOME);
  };

  if (storeInfo?.country === Country.MEXICO) {
    return (
      <MoneyCard
        title={t('descriptions.moneyToReceive')}
        amount={props.amount}
        skeleton={props.skeleton}
        showLoadingDelay={props.showLoadingDelay}
        loadingText="‎"
        hasError={props.hasError}
        color={'primary'}
        onClick={undefined}
        primaryButton={null}
      />
    );
  }

  return (
    <MoneyCard
      title={t('descriptions.moneyToReceive')}
      amount={props.amount}
      skeleton={props.skeleton}
      showLoadingDelay={props.showLoadingDelay}
      loadingText="‎"
      hasError={props.hasError}
      color={'primary'}
      onClick={redirectToFutureIncome}
      primaryButton={
        <Box paddingRight="2">
          <ChevronRightIcon />
        </Box>
      }
    />
  );
}
