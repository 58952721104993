import { PaymentLinkDetailsType } from 'commons/types/PaymentLinkDetailsType';
import { TypeTag } from 'domains/Brazil/commons/components/StatusTag';
import { GetPaymentLinkResponse } from './paymentLinkService';

export default class PaymentLinkDetailsFactory {
  private static formatRejectedMessage = (data: GetPaymentLinkResponse) => {
    if (data.status == TypeTag.RejectedBySecurity)
      return 'card_rejected_by_security';
    else if (data.status == TypeTag.Failed) return data?.rejectedMessage;
    else return undefined;
  };

  public static createFromApiResponseData(
    data: GetPaymentLinkResponse,
  ): PaymentLinkDetailsType {
    return {
      id: data?.id,
      customer: {
        ...data?.customer,
        phone: `${data?.customer?.phone?.countryCode} ${data?.customer?.phone?.areaCode} ${data?.customer?.phone?.number}`,
      },
      description: data?.descriptions,
      linkNumber: data?.linkNumber,
      payment: {
        ...data?.payment,
        cardNumber: Number(data?.payment?.cardNumber),
        rejectedMessage: this.formatRejectedMessage(data),
      },
      paymentUrl: data?.paymentUrl,
      shippingAddress: data?.shippingAddress,
      status: data?.status,
      createdAt: data?.createdAt,
      referenceUUID: data?.referenceUuid,
    };
  }
}
