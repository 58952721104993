import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import RequirePermission from 'App/components/RequirePermission/RequirePermission';
import InactiveStoreRoutes from 'commons/components/InactiveStoreRoutes/InactiveStoreRoutes';
import NotificationProvider from 'commons/components/NotificationProvider/NotificationProvider';
import { PermissionFeature } from 'commons/hooks';
import useStoreInfo from 'commons/hooks/useStoreInfo';
import { HomeMexico, TransactionDetail, Transfer, Withdraws } from 'domains';
import { ROUTES } from '../constants';

function MexicoRoutes(): JSX.Element {
  const { storeInfo } = useStoreInfo();

  const activeStoresRoutes = (
    <Switch>
      <Route exact path={ROUTES.HOME}>
        <Suspense fallback={null}>
          <NotificationProvider>
            <HomeMexico />
          </NotificationProvider>
        </Suspense>
      </Route>

      <Route exact path={`${ROUTES.TRANSACTION_DETAILS}/:orderId`}>
        <Suspense fallback={null}>
          <TransactionDetail />
        </Suspense>
      </Route>

      <Route
        exact
        path={ROUTES.TRANSFER}
        render={() => (
          <RequirePermission permissionFeature={PermissionFeature.Withdraw}>
            <Transfer />
          </RequirePermission>
        )}
      />

      <Route exact path={ROUTES.WITHDRAWS}>
        <Suspense fallback={null}>
          <Withdraws />
        </Suspense>
      </Route>

      <Route path="*" render={() => <Redirect to={ROUTES.HOME} />} />
    </Switch>
  );

  return <>{storeInfo.active ? activeStoresRoutes : <InactiveStoreRoutes />}</>;
}

export default MexicoRoutes;
