import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { track } from '@amplitude/analytics-browser';
import { ROUTES } from 'App/Routes/constants';
import { DocumentType } from 'commons/services/bankingService/bankingService';
import identityService, {
  MissingInfoResponse,
} from 'domains/Brazil/commons/services/identity/identityService';
import { RecipientType } from 'domains/Brazil/commons/types/RecipientType.enum';
import IntroductionStepPage from './pages/IntroductionStepPage/IntroductionStepPage';
import SelectDocumentStepPage from './pages/SelectDocumentStepPage/SelectDocumentsStepPage';
import SelfieUploadStepPage from './pages/SelfieUploadStepPage/SelfieUploadStepPage';

const prefix = 'nuvempago_identity_validation';

export enum IdentityValidationStep {
  INTRODUCTION = 'introduction',
  DOCUMENT = 'document',
  SELFIE = 'selfie',
}

export interface BankingIdentityValidationPageProps {
  nextStep: () => void;
  backStep: () => void;
}

const adaptPersonType = (recipientType: RecipientType): string => {
  return recipientType === RecipientType.Individual ? 'pf' : 'pj';
};

interface LocationState {
  notificationIdOrigin?: string;
}

function IdentityValidation(): JSX.Element {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { notificationIdOrigin } = location.state || {};

  const [isLoading, setIsLoading] = useState(false);
  const [, setError] = useState(false);
  const [missingInfo, setMissingInfo] = useState<MissingInfoResponse | null>(
    null,
  );
  const [selectedDocument, setSelectedDocument] = useState<DocumentType | null>(
    null,
  );

  const [activePage, setActivePage] = useState<IdentityValidationStep>(
    IdentityValidationStep.INTRODUCTION,
  );

  const getMissingInfo = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await identityService.getMissingInfo();
      setError(false);
      setMissingInfo(data);
    } catch (error) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getMissingInfo();
  }, [getMissingInfo]);

  const simpleTrackClick = useCallback(() => {
    if (!missingInfo) {
      return;
    }

    const personType = adaptPersonType(missingInfo?.recipientType);
    const action = `${prefix}_${personType}_${activePage}`;

    track(action);
  }, [missingInfo, activePage]);

  const uploadTrackClick = useCallback(() => {
    if (!missingInfo || !selectedDocument) {
      return;
    }

    const isSelfieStepActive = activePage === IdentityValidationStep.SELFIE;

    const personType = adaptPersonType(missingInfo?.recipientType);
    const normalizedDocument = isSelfieStepActive
      ? 'selfie'
      : selectedDocument.toLowerCase();

    const action = `${prefix}_${personType}_upload_${normalizedDocument}`;

    track(action);
  }, [missingInfo, selectedDocument, activePage]);

  const navigateWithTrack = useCallback(
    (navigationAction: () => void) => {
      const isIntroductionStepActive =
        activePage === IdentityValidationStep.INTRODUCTION;

      if (isIntroductionStepActive) {
        simpleTrackClick();
      } else {
        uploadTrackClick();
      }

      navigationAction();
    },
    [activePage, simpleTrackClick, uploadTrackClick],
  );

  const goToInstructionsStep = useCallback(() => {
    setActivePage(IdentityValidationStep.INTRODUCTION);
  }, []);

  const goToSelectDocumentStep = useCallback(() => {
    setActivePage(IdentityValidationStep.DOCUMENT);
  }, []);

  const goToSelfieStep = useCallback(() => {
    setActivePage(IdentityValidationStep.SELFIE);
  }, []);

  const goToDashboard = useCallback(() => {
    history.push(ROUTES.DASHBOARD);
  }, [history]);

  const renderActivePage = useCallback(() => {
    switch (activePage) {
      case IdentityValidationStep.INTRODUCTION:
        return (
          <IntroductionStepPage
            isLoading={isLoading}
            backStep={goToDashboard}
            nextStep={goToSelectDocumentStep}
          />
        );

      case IdentityValidationStep.DOCUMENT:
        return (
          <SelectDocumentStepPage
            recipientDocument={missingInfo?.document}
            recipientType={missingInfo?.recipientType}
            onSelectDocument={setSelectedDocument}
            backStep={goToInstructionsStep}
            nextStep={() => navigateWithTrack(goToSelfieStep)}
          />
        );

      case IdentityValidationStep.SELFIE:
        return (
          <SelfieUploadStepPage
            selectedDocument={selectedDocument}
            backStep={goToSelectDocumentStep}
            nextStep={() => navigateWithTrack(goToDashboard)}
            notificationIdToDelete={notificationIdOrigin}
          />
        );

      default:
        return (
          <IntroductionStepPage
            isLoading={isLoading}
            backStep={goToDashboard}
            nextStep={goToSelectDocumentStep}
          />
        );
    }
  }, [
    activePage,
    isLoading,
    missingInfo?.document,
    missingInfo?.recipientType,
    selectedDocument,
    goToInstructionsStep,
    goToSelectDocumentStep,
    goToSelfieStep,
    goToDashboard,
    navigateWithTrack,
    notificationIdOrigin,
  ]);

  return renderActivePage();
}

export default IdentityValidation;
