import { Country } from 'App/i18n/enums';
import { useStoreInfo } from 'commons/hooks';

const VIEWS_CONFIGURATION_MX = Object.freeze({
  configuration: false,
  report: false,
  transfer: true,
  withdraws: true,
  transferDetails: true,
  futureIncomes: false,
  minOrderAmountFeatureModal: false,
  helpText: false,
  banners: false,
  refund: false,
});

export type Views = keyof typeof VIEWS_CONFIGURATION_MX;

export const linkAllowedTo = (view: Views, country: string): boolean => {
  if (country === Country.MEXICO) return VIEWS_CONFIGURATION_MX[view];

  return true;
};

export function useShouldLinkTo() {
  const { storeInfo } = useStoreInfo();
  const shouldLinkTo = (view: Views) => {
    return linkAllowedTo(view, storeInfo.country);
  };
  return { shouldLinkTo };
}
