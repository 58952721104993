import React from 'react';
import { useLocation, useParams } from 'react-router';
import PaymentLinkDetail, { IdType } from './PaymentLinkDetail';

export default function PaymentLinkDetailVersionManager(): JSX.Element {
  const { paymentLinkId } = useParams<{ paymentLinkId: string }>();
  const { search } = useLocation();
  const useV2 = new URLSearchParams(search).get('v') === '2';
  return (
    <PaymentLinkDetail
      identifier={paymentLinkId}
      identifierType={useV2 ? IdType.ID : IdType.UUID}
    />
  );
}
