import axios from 'App/axios';
import {
  FeatureName,
  GetFeatureDiscoveryResponse,
} from './featureDiscoveryServiceResponses';

type GetFeatureDiscoveryType = (
  featureName: FeatureName,
) => Promise<GetFeatureDiscoveryResponse>;

const getFeatureDiscovery: GetFeatureDiscoveryType = async (
  featureName: FeatureName,
) => {
  const response = await axios.get(`/admin/feature-discovery/${featureName}`);
  return response.data;
};

type PostFeatureDiscoveryType = (featureName: FeatureName) => Promise<void>;

const postFeatureDiscovery: PostFeatureDiscoveryType = async (
  featureName: FeatureName,
) => {
  const response = await axios.post(`/admin/feature-discovery`, {
    feature_name: featureName,
  });

  return response.data;
};

interface FeatureDiscoveryServiceInterface {
  getFeatureDiscovery: GetFeatureDiscoveryType;
  postFeatureDiscovery: PostFeatureDiscoveryType;
}

const featureDiscoveryService: FeatureDiscoveryServiceInterface = {
  getFeatureDiscovery,
  postFeatureDiscovery,
};

export default featureDiscoveryService;
