import React from 'react';
import Home from './Home';
import HomeSkeleton from './HomeSkeleton';

function HomeMexico(): JSX.Element {
  return <Home />;
}

HomeMexico.Skeleton = HomeSkeleton;
export default HomeMexico;
