import React, { useState } from 'react';
import { Alert, Link, Text } from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';

function DisableNotification() {
  const href = 'https://www.nuvemshop.com.br/termos-de-uso/nuvem-pago';
  const [show, setShow] = useState<boolean>(true);

  const { t } = useTranslationWithPrefix(
    'dashboard.components.kycModal.disableNotification',
  );

  return (
    <Alert
      show={show}
      data-testid="nuvempago-disabled-alert"
      appearance="warning"
      title={t('title')}
      onRemove={() => setShow(false)}
    >
      {t('description')}

      <br />

      <Link as="a" textDecoration="underline" href={href} target="_blank">
        <Text fontSize="base">{t('termsLink')}</Text>
      </Link>
    </Alert>
  );
}

export default DisableNotification;
