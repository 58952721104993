import React from 'react';
import { Text } from '@nimbus-ds/components';
import { Country } from 'App/i18n/enums';
import { useCountry } from 'commons/hooks/useCountry';

type TextByCountryProps = {
  [key in Country]?: string;
};

const TextByCountry: React.FC<TextByCountryProps> = (props) => {
  const { storeCountry } = useCountry();

  if (!props[storeCountry]) return <></>;

  return <Text>{props[storeCountry]}</Text>;
};

export default TextByCountry;
