import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Country } from 'App/i18n/enums';
import NexoSyncRoute from 'App/NexoSyncRoute';
import { useStoreInfo } from 'commons/hooks';
import ArgentinaRoutes from './Argentina/ArgentinaRoutes';
import BrazilRoutes from './Brazil/BrazilRoutes';
import MexicoRoutes from './Mexico/MexicoRoutes';
import NotFoundRoutes from './NotFoundRoutes';

function Routes(): JSX.Element {
  const { storeInfo } = useStoreInfo();
  const mapRoutes = new Map();
  mapRoutes.set(Country.ARGENTINA, <ArgentinaRoutes />);
  mapRoutes.set(Country.BRAZIL, <BrazilRoutes />);
  mapRoutes.set(Country.MEXICO, <MexicoRoutes />);

  function GetRoutesByCountry(key: string) {
    return mapRoutes.get(key) || <NotFoundRoutes />;
  }

  return (
    <Router>
      <NexoSyncRoute>{GetRoutesByCountry(storeInfo.country)}</NexoSyncRoute>
    </Router>
  );
}

export default Routes;
