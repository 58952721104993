import axios from 'App/axios';
import {
  DateRangeType,
  TransactionDetailsType,
  TransactionObjectInterface,
} from 'commons/types';

type GetTransactionDetailType = (
  orderId: string,
) => Promise<TransactionDetailsType>;

const getTransactionDetails: GetTransactionDetailType = async (
  orderId: string,
) => {
  const response = await axios.get(`/admin/transactions/${orderId}`);
  return response.data;
};

type GetTransactionsResponse = {
  transactions: TransactionObjectInterface[];
  page: number;
  itemsPerPage: number;
  itemsCount: number;
};

type GetTransactionsType = ({
  page,
  search,
  status,
  dateRange,
  fromDate,
  toDate,
  hasChargeback,
  items,
}: {
  page: number;
  search?: string;
  status?: string;
  dateRange?: DateRangeType;
  fromDate?: string;
  toDate?: string;
  hasChargeback?: boolean;
  items?: number;
}) => Promise<GetTransactionsResponse>;

const getTransactions: GetTransactionsType = async ({
  page,
  search,
  status,
  dateRange,
  fromDate,
  toDate,
  hasChargeback,
  items,
}) => {
  const params = {
    page,
    items,
    search: search || undefined,
    status: status || undefined,
    range: dateRange || undefined,
    from_date: fromDate || undefined,
    to_date: toDate || undefined,
    has_chargeback: hasChargeback,
  };

  const response = await axios.get(`/admin/transactions`, {
    params,
  });
  return response.data;
};

interface TransactionsServiceInterface {
  getTransactionDetails: GetTransactionDetailType;
  getTransactions: GetTransactionsType;
}

const transactionsService: TransactionsServiceInterface = {
  getTransactionDetails,
  getTransactions,
};

export default transactionsService;
