import React, { useEffect, useState } from 'react';
import { AspectRatio, FileUploader, IconButton } from '@nimbus-ds/components';
import { TrashIcon } from '@nimbus-ds/icons';
import { ThumbnailWithAction } from '@nimbus-ds/patterns';
import { pdfThumbnailPath, useTranslationWithPrefix } from 'commons/utils';
import { MAX_DOCUMENT_SIZE } from 'commons/utils/constants';
import ThumbnailFileUploaderSkeleton from './ThumbnailFileUploaderSkeleton';
import './ThumbnailFileUploader.css';

export interface ThumbnailFileUploaderProps {
  fileName: string;
  helperText: string;
  onSelectFile: (file: File) => void;
  onError: (text: string) => void;
  onDelete: () => void;
  thumbnailFile?: string;
  accept?: string;
  aspectRatio: AspectRatio;
  disabled?: boolean;
  ariaLabel: string;
  maxFileSize?: number;
  resetKey?: boolean; // Alterado para booleano
}

function ThumbnailFileUploader({
  ariaLabel,
  aspectRatio,
  fileName,
  helperText,
  onSelectFile,
  onError,
  onDelete,
  accept = 'image/jpeg,image/png,application/pdf',
  disabled = false,
  thumbnailFile = '',
  maxFileSize = MAX_DOCUMENT_SIZE,
  resetKey = false,
}: ThumbnailFileUploaderProps): JSX.Element {
  const { t } = useTranslationWithPrefix('identityValidation');

  const [thumbnailImageSrc, setThumbnailImageSrc] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchFileType = async () => {
      if (!thumbnailFile) return;
      const file = await fetch(thumbnailFile);

      const blob = await file.blob();
      if (blob.type === 'application/pdf') {
        setThumbnailImageSrc(pdfThumbnailPath);
      } else {
        setThumbnailImageSrc(thumbnailFile);
      }
    };
    fetchFileType();
  }, [thumbnailFile]);

  useEffect(() => {
    setThumbnailImageSrc('');
    setIsLoading(false);
  }, [resetKey]);

  const hasValidType = (file: File) => {
    const VALID_TYPES = accept.split(',');

    return VALID_TYPES.includes(file.type);
  };

  const hasValidSize = (file: File) => {
    return file.size <= maxFileSize && file.size > 0;
  };

  const hasSelectedValidFile = (file: File) => {
    if (!hasValidType(file)) {
      onError(t('errors.invalidType'));
      return false;
    }

    if (!hasValidSize(file)) {
      onError(t('errors.sizeLimit'));
      return false;
    }

    return true;
  };

  const setThumbnail = (file: File) => {
    const isPdf = file.type === 'application/pdf';

    if (isPdf) {
      setThumbnailImageSrc(pdfThumbnailPath);
      return;
    }

    const virtualUrl = URL.createObjectURL(file);
    setThumbnailImageSrc(virtualUrl);
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();

    const files = event.target.files && Object.values(event.target.files);
    const file = files ? files[0] : undefined;

    const isValidFile = file && hasSelectedValidFile(file);

    if (!isValidFile || !file) {
      return;
    }

    setIsLoading(true);
    onSelectFile(file);
    setThumbnail(file);
    setIsLoading(false);
  };

  const onFileDelete = () => {
    setThumbnailImageSrc('');
    onDelete();
  };

  if (isLoading)
    return <ThumbnailFileUploaderSkeleton aspectRatio={aspectRatio} />;

  return (
    <>
      {thumbnailImageSrc ? (
        <ThumbnailWithAction
          data-testid="thumbnail-image"
          width="100%"
          height="100%"
          thumbnail={{
            src: thumbnailImageSrc,
            alt: ariaLabel,
            aspectRatio,
            width: '100%',
            height: '100%',
            style: { objectFit: 'cover' },
          }}
        >
          <IconButton
            data-testid="thumbnail-delete-button"
            source={<TrashIcon color="black" />}
            size="2rem"
            onClick={onFileDelete}
          />
        </ThumbnailWithAction>
      ) : (
        <FileUploader
          aspectRatio={aspectRatio}
          onChange={onFileChange}
          placeholder={helperText}
          disabled={disabled}
          accept={accept}
          id={fileName}
        />
      )}
    </>
  );
}

ThumbnailFileUploader.Skeleton = ThumbnailFileUploaderSkeleton;
export default ThumbnailFileUploader;
