import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { AxiosError } from 'axios';
import {
  GetRefundDetailsResponse,
  refundService,
} from 'commons/services/refundService';
import { MoneyObjectInterface } from 'commons/types';
import Refund, { Caller } from './Refund';

interface RefundDetailParams {
  transactionId: string;
  orderId: string;
}

function TransactionRefund(): JSX.Element {
  const { transactionId, orderId } = useParams<RefundDetailParams>();

  const [refundDetails, setRefundDetails] =
    useState<GetRefundDetailsResponse>();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  /**
   * In v2, the only path param is the transactionId (the 1st parameter),
   * which is the order ID from Nuvemshop. We could also check if the
   * orderId is undefined, but since we have 2 routes to the same page,
   * there is no way to avoid some 404 errors if the store admins try to
   * build a URL by themselves.
   *
   * @todo Remove this check and use only the transactionId when
   * the v2 rollout is complete, as it will be the only path parameter.
   */
  const { search } = useLocation();
  const useV2 = new URLSearchParams(search).get('v') === '2';
  const refundId = useV2 ? transactionId : orderId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const details = await refundService.getRefundDetails(refundId);
        setRefundDetails(details);
        setIsSuccess(true);
      } catch (error: any) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [refundId]);

  const onRefund = useCallback(
    async (amount: MoneyObjectInterface, restock?: boolean) => {
      try {
        await refundService.refund(refundId, amount, restock);
      } catch (error: unknown) {
        const { response } = error as AxiosError;
        throw response;
      }
    },
    [refundId],
  );

  return (
    <Refund
      {...{
        refundId: transactionId,
        caller: Caller.TransactionRefund,
        isError,
        isLoading,
        isSuccess,
        data: refundDetails,
        onRefund,
        confirmationDelaySeconds: 3,
      }}
    />
  );
}

export default TransactionRefund;
