import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from 'App/Routes/constants';
import { Landing } from 'domains';

const InactiveStoreRoutes = () => {
  return (
    <Switch>
      <Route exact path={`${ROUTES.LANDING}`}>
        <Suspense fallback={null}>
          <Landing />
        </Suspense>
      </Route>

      <Route path="*" render={() => <Redirect to={ROUTES.LANDING} />} />
    </Switch>
  );
};

export default InactiveStoreRoutes;
