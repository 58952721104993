import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import RequireRole from 'App/components/RequireRole';
import NotificationProvider from 'commons/components/NotificationProvider/NotificationProvider';
import { Roles } from 'commons/components/Roles/Roles.enum';
import { useStoreInfo } from 'commons/hooks';
import { Landing } from 'domains';
import IdentityValidation from 'domains/Brazil/Banking/IdentityValidation/IdentityValidation';
import Dashboard from 'domains/Brazil/Dashboard/Dashboard';
import RegisterIdentityInfo from 'domains/Brazil/Identity/pages/Registration/RegisterIdentityInfo';
import LendingPayment from 'domains/Brazil/Lending/Payment/Payment';
import LivenessValidation from 'domains/Brazil/Liveness/LivenessValidation';
import NotificationBRProvider from 'domains/Brazil/Notifications/Notification.context';
import { EmptyStatePage, PaymentLink } from 'domains/Brazil/PaymentLink/';
import PaymentLinkDetail from 'domains/Brazil/PaymentLink/pages/Details';
import PaymentLinkRefund from 'domains/Brazil/Refund/PaymentLinkRefund';
import TransactionRefund from 'domains/Brazil/Refund/TransactionRefund';
import PaymentLinkRefundReceipt from 'domains/Brazil/RefundReceipt/PaymentLinkRefundReceipt';
import TransactionRefundReceipt from 'domains/Brazil/RefundReceipt/TransactionRefundReceipt';
import TransactionDetail from 'domains/Brazil/Transactions/pages/Details';
import Withdrawal from 'domains/Brazil/Withdraw/withdrawal';
import { ROUTES } from '../constants';

function BrazilRoutes(): JSX.Element {
  const { storeInfo } = useStoreInfo();

  const activeRoutes = (
    <NotificationBRProvider>
      <NotificationProvider>
        <Switch>
          <Route exact path={[ROUTES.HOME, ROUTES.PAYMENT_LINK]}>
            <Suspense fallback={null}>
              <EmptyStatePage />
            </Suspense>
          </Route>

          <Route exact path={ROUTES.PAYMENT_LINK_FORM}>
            <Suspense fallback={null}>
              <PaymentLink />
            </Suspense>
          </Route>

          <Route exact path={`${ROUTES.PAYMENT_LINK_DETAILS}/:paymentLinkId`}>
            <Suspense fallback={null}>
              <PaymentLinkDetail />
            </Suspense>
          </Route>

          <Route
            exact
            path={[
              /** @todo remove legacy transaction refund route after transaction details v2 rollout */
              `${ROUTES.ORDER_REFUND}/:transactionId/:orderId`, // v1, legacy
              `${ROUTES.ORDER_REFUND}/:transactionId`, // v2
            ]}
          >
            <Suspense fallback={null}>
              <RequireRole role={Roles.REFUND} redirectPath={ROUTES.DASHBOARD}>
                <TransactionRefund />
              </RequireRole>
            </Suspense>
          </Route>

          <Route exact path={`${ROUTES.PAYMENT_LINK_REFUND}/:referenceUUID`}>
            <Suspense fallback={null}>
              <RequireRole role={Roles.REFUND} redirectPath={ROUTES.DASHBOARD}>
                <PaymentLinkRefund />
              </RequireRole>
            </Suspense>
          </Route>

          <Route exact path={[ROUTES.DASHBOARD]}>
            <Suspense fallback={null}>
              <Dashboard />
            </Suspense>
          </Route>

          <Route exact path={`${ROUTES.TRANSACTION_DETAILS}/:transactionId`}>
            <Suspense fallback={null}>
              <TransactionDetail />
            </Suspense>
          </Route>

          <Route exact path={`${ROUTES.WITHDRAWS}`}>
            <Suspense fallback={null}>
              <Withdrawal />
            </Suspense>
          </Route>

          <Route exact path={`${ROUTES.BANKING_IDENTITY_VALIDATION}`}>
            <Suspense fallback={null}>
              <IdentityValidation />
            </Suspense>
          </Route>

          <Route
            exact
            path={[
              /** @todo remove legacy transaction refund receipt route after transaction details v2 rollout */
              `${ROUTES.TRANSACTION_DETAILS}/:transactionId/:orderId${ROUTES.REFUND_RECEIPT}`, // v1, legacy
              `${ROUTES.TRANSACTION_DETAILS}/:transactionId${ROUTES.REFUND_RECEIPT}`, // v2
            ]}
          >
            <Suspense fallback={null}>
              <TransactionRefundReceipt />
            </Suspense>
          </Route>

          <Route
            exact
            path={`${ROUTES.PAYMENT_LINK}/:referenceUUID${ROUTES.REFUND_RECEIPT}`}
          >
            <Suspense fallback={null}>
              <PaymentLinkRefundReceipt />
            </Suspense>
          </Route>

          <Route exact path={`${ROUTES.LENDING_PAYMENT}`}>
            <Suspense fallback={null}>
              <LendingPayment />
            </Suspense>
          </Route>

          <Route exact path={ROUTES.LIVENESS_VALIDATION}>
            <Suspense fallback={null}>
              <LivenessValidation />
            </Suspense>
          </Route>

          <Route exact path={`${ROUTES.IDENTITY}/register`}>
            <Suspense fallback={null}>
              <RegisterIdentityInfo />
            </Suspense>
          </Route>
        </Switch>
      </NotificationProvider>
    </NotificationBRProvider>
  );

  const inactiveRoutes = (
    <Switch>
      <Route exact path={`${ROUTES.LANDING}`}>
        <Suspense fallback={null}>
          <Landing />
        </Suspense>
      </Route>

      <Route path="*" render={() => <Redirect to={ROUTES.LANDING} />} />
    </Switch>
  );

  return <>{storeInfo?.active ? activeRoutes : inactiveRoutes}</>;
}

export default BrazilRoutes;
