import React, { useState } from 'react';
import { Box, Title } from '@nimbus-ds/components';
import { useQuery } from '@tanstack/react-query';
import { ErrorMessage } from 'commons/components';
import { usePrevious } from 'commons/hooks';
import { useGetSalesFilter } from 'commons/hooks/useGetSalesFilter';
import { transactionsService } from 'commons/services';
import { TransactionObjectInterface } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';
import { transactionStatusLabelMap } from 'domains/Home/StatusLabel';
import { useIntersectionObserver } from 'usehooks-ts';
import TransactionsFilter from '../TransactionsFilter';
import HomeTableBody from './HomeTableBody';
import HomeTableMobileSkeleton from './HomeTableMobileSkeleton';

function HomeTableMobile(): JSX.Element | null {
  const { t } = useTranslationWithPrefix('home.homeTable');
  const [transactions, setTransactions] = useState(
    [] as TransactionObjectInterface[],
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoadingFirstMount, setIsLoadingFirstMount] = useState(true);
  const [hasEmptyTransactions, setHasEmptyTransactions] = useState(false);

  const { filters, updateFilters } = useGetSalesFilter();
  const { search, status, fromDate, toDate, dateRange } = filters;

  const previousFilters = usePrevious({
    search,
    status,
    fromDate,
    toDate,
    dateRange,
  });

  const verifyEmptyTransactions = (
    transactions: TransactionObjectInterface[],
  ) => {
    const searchPerformed = search || status || dateRange;
    if (!transactions.length && !searchPerformed) {
      setHasEmptyTransactions(true);
    }
  };

  const filtersChanged = (): boolean => {
    return (
      search !== previousFilters?.search ||
      status !== previousFilters?.status ||
      fromDate !== previousFilters?.fromDate ||
      toDate !== previousFilters?.toDate ||
      dateRange !== previousFilters?.dateRange
    );
  };

  const transactionsQuery = useQuery(
    ['transactions', pageNumber, search, status, dateRange, fromDate, toDate],
    () =>
      transactionsService.getTransactions({
        page: pageNumber,
        search,
        status,
        dateRange,
        fromDate,
        toDate,
        hasChargeback: status === transactionStatusLabelMap.chargebacked,
      }),
    {
      onSuccess: (response) => {
        setIsLoadingFirstMount(false);
        if (pageNumber === 1) {
          setTransactions([]);
        }
        if (filtersChanged() && pageNumber !== 1) {
          setPageNumber(1);
          return;
        }
        verifyEmptyTransactions(response.transactions);
        setTransactions((oldTransactions) => [
          ...oldTransactions,
          ...response.transactions,
        ]);
        setTotalPages(response.itemsCount / response.itemsPerPage);
        setTotalItems(response.itemsCount);
      },
    },
  );

  const fetchMoreItems = () => {
    if (transactionsQuery.isLoading || pageNumber >= totalPages) return;
    const nextPageNumber = pageNumber + 1;
    setPageNumber(nextPageNumber);
  };

  const { ref } = useIntersectionObserver({
    onChange: (isIntersecting) => isIntersecting && fetchMoreItems(),
  });

  const title = t('title');

  if (transactionsQuery.isError) {
    return (
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
        <Title as="h2">{title}</Title>
        <ErrorMessage />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
      {isLoadingFirstMount ? (
        <Title.Skeleton as="h2" />
      ) : (
        <Title as="h2">{title}</Title>
      )}
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
        <TransactionsFilter
          skeleton={isLoadingFirstMount}
          updateFilters={updateFilters}
          transactionsCount={
            search && !transactionsQuery.isLoading ? totalItems : undefined
          }
        />
        <HomeTableBody
          hasEmptyTransactions={hasEmptyTransactions}
          transactions={transactions}
          isLoading={transactionsQuery.isLoading}
          aRef={ref}
        />
      </Box>
    </Box>
  );
}

HomeTableMobile.Skeleton = HomeTableMobileSkeleton;
export default HomeTableMobile;
