import React, { useEffect, useState } from 'react';
import { Box, Title } from '@nimbus-ds/components';
import { UploadIcon, UserIcon } from '@nimbus-ds/icons';
import { Page } from '@nimbus-ds/patterns';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import AppLayout from 'App/components/AppLayout';
import nexo from 'App/nexoClient';
import { ROUTES } from 'App/Routes/constants';
import { useIsMobile } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';
import ContentOrError from '../../components/ContentOrError';
import FooterButtons from '../../components/FooterButtons';
import DocumentIcon from '../../components/icons/DocumentIcon';
import IconText from '../../components/IconText';
import { BankingIdentityValidationPageProps } from '../../IdentityValidation';
import IntroductionStepPageSkeleton from './IntroductionStepPage.skeleton';

function InstructionsContainer(): JSX.Element {
  const isMobile = useIsMobile();

  const { t } = useTranslationWithPrefix(
    'banking.identityValidation.instructionsPage.instructionsContainer',
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="4"
      justifyContent="flex-start"
    >
      <Box>
        <Title as={isMobile ? 'h4' : 'h2'}>{t('title')}</Title>
      </Box>
      <Box display="flex" flexDirection="column" gap="4">
        <IconText icon={<DocumentIcon />} text={t('itemDocument')} />

        <IconText icon={<UserIcon />} text={t('itemSelfie')} />

        <IconText icon={<UploadIcon />} text={t('itemUpload')} />
      </Box>
    </Box>
  );
}

type IdentityValidationIntroductionStepPageProps =
  BankingIdentityValidationPageProps & {
    isLoading?: boolean;
  };

function IntroductionStepPageComponent({
  nextStep,
  backStep,
}: IdentityValidationIntroductionStepPageProps): JSX.Element | null {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const isMobile = useIsMobile();
  const { t: navT } = useTranslationWithPrefix('navigation');
  const { t } = useTranslationWithPrefix(
    'banking.identityValidation.instructionsPage',
  );

  useEffect(() => {
    navigateHeader(nexo, { goTo: ROUTES.DASHBOARD, text: navT('back') });

    if (!loading) return;
    if (error) return;

    setLoading(true);
    setError(false);
  }, [loading, error, navT]);

  if (!loading && !error) setLoading(true);

  const refreshOnError = () => {
    setError(false);
  };

  return (
    <AppLayout
      title=""
      backLinkText={isMobile ? '' : undefined}
      backLinkPath={ROUTES.DASHBOARD}
    >
      <Box
        backgroundColor={isMobile ? 'neutral-background' : undefined}
        padding="none"
        data-testid="introduction-step-page"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <ContentOrError error={error} refreshHandler={refreshOnError}>
          <Box height="400px" maxWidth="1200px">
            <Page.Header
              paddingLeft="none"
              title={t('title')}
              subtitle={t('subtitle')}
            />
            <Box
              id="instructions"
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              alignItems="center"
              height="100%"
            >
              <Box
                padding="4"
                flexDirection="column"
                alignItems="flex-start"
                marginX={{ xl: '4', lg: '4', md: '4', xs: 'none' }}
              >
                <img
                  alt={t('title')}
                  src={'/assets/img/identity-validation.png'}
                  width="100%"
                />
              </Box>

              <Box display="flex" gap="4" flexDirection="column">
                <InstructionsContainer />
                <FooterButtons
                  primaryButtonLabel={t('buttons.primary')}
                  primaryButtonHandler={nextStep}
                  secondaryButtonLabel={t('buttons.secondary')}
                  secondaryButtonHandler={backStep}
                />
              </Box>
            </Box>
          </Box>
        </ContentOrError>
      </Box>
    </AppLayout>
  );
}

function IntroductionStepPage({
  isLoading,
  ...props
}: IdentityValidationIntroductionStepPageProps): JSX.Element {
  if (isLoading) {
    return <IntroductionStepPageSkeleton />;
  }

  return <IntroductionStepPageComponent {...props} />;
}

export default IntroductionStepPage;
