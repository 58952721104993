import React from 'react';
import { useHistory } from 'react-router';
import { Box, Text } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { RestrictedLink } from 'commons/components';
import { useStoreInfo } from 'commons/hooks';
import { TransactionObjectInterface } from 'commons/types';
import {
  formatCurrency,
  formatDate,
  isOldTransaction,
  redirectToTransaction,
  useTranslationWithPrefix,
} from 'commons/utils';
import StatusLabel from '../../StatusLabel';

interface HomeTableMobileRowInterface {
  order: TransactionObjectInterface;
  mock?: boolean;
  aRef?: (node?: Element | null) => void;
}

export default function HomeTableMobileRow({
  order,
  mock,
  aRef,
}: HomeTableMobileRowInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('home.homeTable');
  const { storeInfo } = useStoreInfo();
  const history = useHistory();

  const isAbandonedCart = order.orderNumber === -1;
  const orderNumber = isAbandonedCart ? '---' : `#${order.orderNumber}`;
  return (
    <DataList.Row gap="3">
      <Box
        display="flex"
        flexDirection="column"
        gap="3"
        width="100%"
        ref={aRef}
      >
        <Box display="flex" justifyContent="space-between">
          {mock ? (
            <Text>Ejemplo</Text>
          ) : (
            <RestrictedLink
              view="transferDetails"
              whenRestricted="dummy"
              appearance="primary"
              onClick={() =>
                redirectToTransaction(
                  order.id,
                  isOldTransaction(order.createdAt),
                  isAbandonedCart,
                  history,
                )
              }
            >
              {t('order')} {orderNumber}
            </RestrictedLink>
          )}
          <Text>{formatDate(storeInfo, order.createdAt)}</Text>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Text>{order.customer.name}</Text>
          <Text>
            {`${formatCurrency(order.remainingAmountToRefund, storeInfo)}`}
          </Text>
        </Box>
        <StatusLabel
          labelKey="1"
          statusType={order.status}
          chargeback={order.chargeback}
        />
      </Box>
    </DataList.Row>
  );
}
