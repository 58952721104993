import React from 'react';
import { Box, Card, Text, Title } from '@nimbus-ds/components';
import {
  ChargesDashboardStatus,
  FailureReason,
} from 'commons/services/dashboardServiceV2/dashboard.service';
import { getStatusTagCode } from 'commons/services/dashboardServiceV2/status-tag.service';
import { TransactionDetailsType } from 'commons/types';
import { Currency } from 'commons/types/Currency.enum';
import { useTranslationWithPrefix } from 'commons/utils';
import { round } from 'commons/utils/math';
import MoneyField from 'domains/Brazil/commons/components/MoneyField';
import { showFeesForStatus } from 'domains/Brazil/commons/utils/status';

type ValueProps = {
  isNegative?: boolean;
} & Pick<
  React.ComponentProps<typeof MoneyField>,
  'value' | 'type' | 'fontWeight'
>;

function Value({ value, isNegative, fontWeight, type }: Readonly<ValueProps>) {
  return (
    <MoneyField
      color={isNegative ? 'danger-textLow' : 'neutral-textLow'}
      textAlign="right"
      fontSize="base"
      fontWeight={fontWeight}
      prefixString={isNegative ? '- ' : undefined}
      value={Number(value)}
      currency={Currency.BRL}
      type={type}
    />
  );
}

type ValueRowProps = Partial<Pick<ValueProps, 'value' | 'isNegative'>> & {
  label: string;
};

function ValueRow({ value, label, isNegative }: Readonly<ValueRowProps>) {
  return value ? (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <Box>
        <Text fontSize="base" textAlign="left" color="neutral-textLow">
          {label}
        </Text>
      </Box>
      <Box>
        <Value value={Number(value)} isNegative={isNegative} />
      </Box>
    </Box>
  ) : null;
}

export type SummaryCardProps = {
  readonly details: TransactionDetailsType;
};

export function SummaryCard({ details }: SummaryCardProps): JSX.Element {
  const { t } = useTranslationWithPrefix('transactions.details.summary');

  const status = getStatusTagCode(
    details.status as ChargesDashboardStatus,
    details.failureReason as FailureReason,
  );
  const showFees = showFeesForStatus(status);

  const totalFees = round(
    (details.feesAndTaxes.processingFee?.value ?? 0) +
      (details.feesAndTaxes.installmentsFee?.value ?? 0),
  );
  const totalAmount = showFees
    ? details.netAmount.value
    : details.order.price.value;

  return (
    <Box minWidth="343px">
      <Card padding="none">
        <Card.Header padding="base" title={t('title')} />
        <Card.Body padding="base">
          <Box display="flex" flexDirection="column" gap="2-5">
            <ValueRow value={details.order.price.value} label={t('paid')} />
            {showFees && (
              <>
                <ValueRow value={totalFees} label={t('fee')} isNegative />
                <ValueRow
                  value={details.totalRefunds?.customerRefunds.value}
                  label={t('refunded')}
                  isNegative
                />
                <ValueRow
                  value={details.totalRefunds?.storeRefunds.value}
                  label={t('cancelFee')}
                />
              </>
            )}
          </Box>
        </Card.Body>
        <Box backgroundColor="neutral-surfaceDisabled" padding="none">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            padding="4"
          >
            <Text
              fontSize="base"
              textAlign="left"
              fontWeight="bold"
              color="neutral-textLow"
            >
              {t('total')}
            </Text>
            <Value value={totalAmount} fontWeight="bold" />
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

function Skeleton(): JSX.Element {
  return (
    <Box minWidth="343px" data-testid="summaryCardSkeleton">
      <Card padding="none">
        <Card.Body padding="none">
          <Box display="flex" flexDirection="column" gap="4" padding="4">
            <Box>
              <Title.Skeleton width="79px" height="28px" />
            </Box>
            <Box display="flex" flexDirection="column" gap="2-5">
              {[1, 2].map((i) => (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  gap="16"
                  key={`text-${i}`}
                >
                  <Box flexGrow="1">
                    <Text.Skeleton width="100%" fontSize="highlight" />
                  </Box>
                  <Box>
                    <Text.Skeleton width="73px" fontSize="highlight" />
                  </Box>
                </Box>
              ))}
            </Box>
            <Text.Skeleton width="100%" height="26px" fontSize="highlight" />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            padding="4"
            gap="16"
            backgroundColor="neutral-surfaceDisabled"
          >
            <Box flexGrow="1">
              <Text.Skeleton width="100%" fontSize="highlight" />
            </Box>
            <Box>
              <Text.Skeleton width="104px" fontSize="highlight" />
            </Box>
          </Box>
        </Card.Body>
      </Card>
    </Box>
  );
}

SummaryCard.Skeleton = Skeleton;

export default SummaryCard;
