import React from 'react';
import { Country } from 'App/i18n/enums';
import { useCountry } from 'commons/hooks/useCountry';

interface Props {
  countries: Country[];
  children: React.ReactNode;
}

const RenderForCountry = ({ children, countries }: Props) => {
  const { storeCountry } = useCountry();

  if (!countries.includes(storeCountry)) {
    return null;
  }
  return <>{children}</>;
};

export default RenderForCountry;
