import React, { useEffect, useState } from 'react';
import { Box, Card, Tag, Title } from '@nimbus-ds/components';
import { FileIcon, SunIcon } from '@nimbus-ds/icons';
import { Page } from '@nimbus-ds/patterns';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import AppLayout from 'App/components/AppLayout';
import nexo from 'App/nexoClient';
import { ROUTES } from 'App/Routes/constants';
import { useIsMobile, useToast } from 'commons/hooks';
import { notificationService } from 'commons/services';
import { bankingService } from 'commons/services/bankingService';
import {
  Document,
  DocumentSide,
  DocumentType,
} from 'commons/services/bankingService/bankingService';
import { useTranslationWithPrefix } from 'commons/utils';
import { AlertFileSizeError } from '../../components/AlertFileSizeError';
import ContentOrError from '../../components/ContentOrError';
import FooterButtons from '../../components/FooterButtons';
import FaceIcon from '../../components/icons/FaceIcon';
import UserIdentityIcon from '../../components/icons/UserIdentityIcon';
import IconText from '../../components/IconText';
import ThumbnailFileUploader from '../../components/ThumbnailFileUploader';
import { BankingIdentityValidationPageProps } from '../../IdentityValidation';

type SelfieUploadStepPageProps = BankingIdentityValidationPageProps & {
  selectedDocument: DocumentType | null;
  notificationIdToDelete?: string;
};

type InstructionsListProps = {
  showTitle: boolean;
};

function InstructionsList({ showTitle }: InstructionsListProps): JSX.Element {
  const { t } = useTranslationWithPrefix(
    'banking.identityValidation.selfieUploadPage.instructionsContainer',
  );

  return (
    <Box display="flex" flexDirection="column" gap="4">
      {showTitle && <Title as="h6">{t('title')}</Title>}

      <IconText icon={<SunIcon size="medium" />} text={t('itemSun')} />

      <IconText icon={<FaceIcon />} text={t('itemFace')} />

      <IconText
        icon={<UserIdentityIcon />}
        text={t('itemSelfieWithDocument')}
      />

      <IconText icon={<FileIcon />} text={t('itemFile')} />
    </Box>
  );
}

function SelfieUploadStepPage({
  nextStep,
  backStep,
  selectedDocument,
  notificationIdToDelete,
}: SelfieUploadStepPageProps): JSX.Element | null {
  const isMobile = useIsMobile();
  const { showToastSuccess, showToastError } = useToast();
  const { t: navT } = useTranslationWithPrefix('navigation');
  const { t } = useTranslationWithPrefix(
    'banking.identityValidation.selfieUploadPage',
  );

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [hasFileSizeError, setFileSizeError] = useState(false);
  const [MAX_10MB] = useState(10485760);
  const [selfieFile, setSelfieFile] = useState<File | null>(null);

  useEffect(() => {
    navigateHeader(nexo, { goTo: ROUTES.DASHBOARD, text: navT('back') });

    if (!isLoading) return;
    if (error) return;

    setLoading(true);
    setError(false);
  }, [isLoading, error, navT, selectedDocument]);

  const refreshOnError = () => {
    setError(false);
  };

  function onSelectFileHandler(file: File) {
    setSelfieFile(file);
    showToastSuccess(t('toast.success'));
    setFileSizeError(false);
    setError(false);
  }

  function onDeleteFileHandler() {
    setSelfieFile(null);
    setFileSizeError(false);
  }

  function onErrorHandler() {
    setFileSizeError(true);
    showToastError(t('toast.error'));
  }

  async function uploadFile() {
    setLoading(true);

    if (!selfieFile) return;

    const extension = bankingService.extractFileExtension(selfieFile);

    if (!extension) {
      setError(true);
      return;
    }

    const document: Document = {
      documentType: DocumentType.SELFIE,
      documentSide: DocumentSide.FRONT,
    };

    try {
      await bankingService.addAccountDocument({ document, file: selfieFile });

      if (notificationIdToDelete) {
        await notificationService.deleteNotification(notificationIdToDelete);
      }

      nextStep();
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box data-testid="SelfieUploadStepPage">
      <AppLayout
        title=""
        backLinkText={isMobile ? '' : undefined}
        backLinkPath={ROUTES.DASHBOARD}
      >
        {hasFileSizeError && <AlertFileSizeError />}
        <ContentOrError error={error} refreshHandler={refreshOnError}>
          <Box
            paddingY="4"
            height={isMobile ? '100%' : '400px'}
            maxWidth="1200px"
          >
            <Page.Header paddingX="none" title={t('title')} />
            <Tag appearance="neutral">{t('step')}</Tag>
            <Box
              id="instructions"
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              alignItems="center"
              marginTop="2"
            >
              <Card>
                <Card.Header>
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Title as="h4">
                      {t('selfieWithDocument', { document: selectedDocument })}
                    </Title>
                  </Box>
                </Card.Header>
                <Card.Body>
                  <Box
                    display="flex"
                    gap="6"
                    paddingTop="2"
                    flexDirection={isMobile ? 'column' : 'row-reverse'}
                  >
                    <Box
                      display="flex"
                      flex="1"
                      flexDirection="column"
                      gap="4"
                      alignItems="flex-start"
                    >
                      <InstructionsList showTitle={!isMobile} />
                    </Box>
                    <Box flex="1">
                      <ThumbnailFileUploader
                        data-testid="ThumbnailFileUploader"
                        accept="image/jpeg,image/png"
                        ariaLabel="selfie"
                        aspectRatio="16/9"
                        fileName="selfie"
                        helperText="Selfie"
                        maxFileSize={MAX_10MB}
                        onDelete={onDeleteFileHandler}
                        onError={onErrorHandler}
                        onSelectFile={onSelectFileHandler}
                      />
                    </Box>
                  </Box>
                </Card.Body>
              </Card>
            </Box>
            <Box display="flex" gap="4" flexDirection="column">
              <FooterButtons
                primaryButtonHandler={uploadFile}
                secondaryButtonHandler={backStep}
                isDisabled={hasFileSizeError || !selfieFile}
                isSubmitting={isLoading}
              />
            </Box>
          </Box>
        </ContentOrError>
      </AppLayout>
    </Box>
  );
}

export default SelfieUploadStepPage;
