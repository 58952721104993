import React, { ReactText, useEffect, useState } from 'react';
import { Box, Title } from '@nimbus-ds/components';
import { ErrorMessage } from 'commons/components';
import {
  SalesFilter,
  useGetSalesFilter,
} from 'commons/hooks/useGetSalesFilter';
import { transactionsService } from 'commons/services';
import { TransactionObjectInterface } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';
import { transactionStatusLabelMap } from 'domains/Home/StatusLabel';
import EmptyTransactions from '../EmptyTransactions';
import TransactionsFilter from '../TransactionsFilter';
import DataTableDesktop from './DataTableDesktop';
import HomeTableDesktopSkeleton from './HomeTableDesktopSkeleton';

function HomeTableDesktop(): JSX.Element | null {
  const [transactions, setTransactions] = useState(
    [] as TransactionObjectInterface[],
  );
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingFirstMount, setIsLoadingFirstMount] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [hasEmptyTransactions, setHasEmptyTransactions] = useState(false);
  const [filtersHaveBeenChanged, setFiltersHaveBeenChanged] = useState(false);

  const { filters, updateFilters } = useGetSalesFilter();
  const { dateRange, fromDate, salesPage, search, status, toDate } = filters;

  const { t } = useTranslationWithPrefix('home.homeTable');

  useEffect(() => {
    const verifyEmptyTransactions = (
      transactions: TransactionObjectInterface[],
    ) => {
      const searchPerformed = search || status || dateRange;
      if (!transactions.length && !searchPerformed) {
        setHasEmptyTransactions(true);
      }
    };

    async function fetchTransactions() {
      setIsLoading(true);
      try {
        if (filtersHaveBeenChanged && salesPage !== 1) {
          handleChangePage(1);
          return;
        }
        const hasChargeback = status === transactionStatusLabelMap.chargebacked;
        const response = await transactionsService.getTransactions({
          page: salesPage ?? 1,
          search,
          status,
          dateRange,
          fromDate,
          toDate,
          hasChargeback,
        });
        verifyEmptyTransactions(response.transactions);
        setTransactions(response.transactions);
        setTotalPages(Math.ceil(response.itemsCount / response.itemsPerPage));
        setTotalItems(response.itemsCount);
        setItemsPerPage(response.itemsPerPage);
      } catch (error) {
        setHasError(true);
      }
      setIsLoading(false);
      setIsLoadingFirstMount(false);
    }

    fetchTransactions();
    setFiltersHaveBeenChanged(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesPage, search, status, dateRange, fromDate, toDate]);

  const handleChangePage = (page: number) => {
    updateFilters({
      dateRange,
      fromDate,
      salesPage: page,
      search,
      status,
      toDate,
    });
  };

  const handleChangeFilters = (filters: SalesFilter) => {
    setFiltersHaveBeenChanged(true);
    updateFilters({ ...filters });
  };

  if (hasError) {
    return (
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
        <Title as="h2">{t('title') as ReactText}</Title>
        <ErrorMessage />
      </Box>
    );
  }

  if (isLoadingFirstMount) return <HomeTableDesktopSkeleton />;

  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
      <Title as="h2">{t('title') as ReactText}</Title>
      <TransactionsFilter
        skeleton={isLoadingFirstMount}
        updateFilters={handleChangeFilters}
        transactionsCount={search && !isLoading ? totalItems : undefined}
      />
      {hasEmptyTransactions ? (
        <EmptyTransactions />
      ) : (
        <DataTableDesktop
          transactions={transactions}
          pageNumber={salesPage ?? 1}
          totalPages={totalPages}
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          onPageChange={handleChangePage}
          isLoading={isLoading}
        />
      )}
    </Box>
  );
}

HomeTableDesktop.Skeleton = HomeTableDesktopSkeleton;
export default HomeTableDesktop;
