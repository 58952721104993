import axios from 'App/axios';
import { AxiosResponse } from 'axios';
import { MoneyObjectInterface } from 'commons/types';
import { PaymentLinkDetailsType } from 'commons/types/PaymentLinkDetailsType';
import { GetRefundReceiptDetailsResponse } from '../refundReceiptService';
import { GetRefundReceiptDetailsType } from '../refundReceiptService/refundReceiptService';
import { GetRefundDetailsResponse } from '../refundService';
import { GetRefundDetailsType } from '../refundService/refundService';
import PaymentLinkDetailsFactory from './paymentLinkDetailsFactory';

export enum ExpirationTime {
  FifteenMinutes = 900,
  ThirtyMinutes = 1_800,
  OneHour = 3_600,
  TwoHours = 7_200,
  OneDay = 86_400,
  TwoDays = 172_800,
}

export interface paymentLinkInfo {
  customerName: string;
  amount: number;
  expirationTime: ExpirationTime;
  description: string;
  linkNumber: number;
  paymentUrl: string;
  paymentSuccessUrl: string;
}

export interface PaymentLinkRefundArgs {
  referenceUUID: string;
  amount: MoneyObjectInterface;
}

export type GetPaymentLinkResponse = {
  id: string;
  customer: {
    name: string;
    email: string;
    document: string;
    phone: {
      countryCode: string;
      areaCode: string;
      number: string;
    };
    type: string;
  };
  descriptions: string;
  linkNumber: string;
  payment: {
    paid: number;
    fee: number;
    refunded: number;
    total: number;
    cardNumber: string;
    brand: string;
    installments: number;
    holderName: string;
    document: string;
    cancelFee: number;
  };
  rejectedMessage?: string;
  paymentUrl: string;
  shippingAddress: {
    street: string;
    city: string;
    state: string;
    zipCode: string;
  };
  status: string;
  createdAt: string;
  referenceUuid: string;
};

export interface RefundTransactions {
  id: string;
  amount: {
    currency: string;
    value: number;
  };
}

const ExpirationTimeMap = new Map([
  ['FifteenMinutes', 900],
  ['ThirtyMinutes', 1800],
  ['OneHour', 3600],
  ['TwoHours', 7200],
  ['OneDay', 86400],
  ['TwoDays', 172800],
]);

const getAvailablePaymentMethods = (): string[] => {
  return ['credit-card'];
};

const getExpirationOptions = (): Map<string, any> => {
  return ExpirationTimeMap;
};

const createPaymentLink = async (
  data: Omit<paymentLinkInfo, 'linkNumber' | 'paymentUrl'>,
): Promise<paymentLinkInfo> => {
  const response = await axios.post(`/admin/payment-links`, data);

  return response.data;
};

const getDetailsByUuid = async (
  referenceUUID: string,
): Promise<PaymentLinkDetailsType> => {
  const { data } = await axios.get<GetPaymentLinkResponse>(
    `/admin/payment-links/${referenceUUID}`,
  );
  return PaymentLinkDetailsFactory.createFromApiResponseData(data);
};

const getDetailsById = async (id: string): Promise<PaymentLinkDetailsType> => {
  const { data } = await axios.get<GetPaymentLinkResponse>(
    `/admin/payment-links`,
    { params: { id } },
  );

  return PaymentLinkDetailsFactory.createFromApiResponseData(data);
};

const validateAmount = async (amount: number): Promise<boolean> => {
  try {
    const response: AxiosResponse = await axios.get(
      `/admin/validate-amount?amount=${amount}`,
    );
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

const refundLegacy = async (
  transactionId: string,
  storeId: string,
): Promise<boolean> => {
  try {
    const response: AxiosResponse = await axios.post(`/orders/refund`, {
      storeId: parseInt(storeId),
      transactionId: `${transactionId}`,
      origin: 'PaymentLink',
    });
    if (response.status >= 200 && response.status < 300) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

const refund = async ({
  referenceUUID,
  amount,
}: PaymentLinkRefundArgs): Promise<unknown> => {
  const response: AxiosResponse = await axios.post(
    `/admin/payment-link/${referenceUUID}/refund`,
    {
      amount,
      origin: 'PaymentLink',
    },
  );
  return response.data;
};

const getRefundDetails: GetRefundDetailsType = async (
  referenceUUID: string,
): Promise<GetRefundDetailsResponse> => {
  try {
    const response = await axios.get(
      `/admin/payment-link/${referenceUUID}/refund-details`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getRefundReceiptDetails: GetRefundReceiptDetailsType = async (
  referenceUUID: string,
) => {
  try {
    const response = await axios.get(
      `/admin/payment-link/${referenceUUID}/refund-receipt`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

interface PaymentLinkServiceInterface {
  getAvailablePaymentMethods: () => string[];
  getExpirationOptions: () => Map<string, any>;
  createPaymentLink: (
    data: Omit<paymentLinkInfo, 'linkNumber' | 'paymentUrl'>,
  ) => Promise<paymentLinkInfo>;
  getDetailsByUuid: (uuid: string) => Promise<PaymentLinkDetailsType>;
  getDetailsById: (id: string) => Promise<PaymentLinkDetailsType>;
  validateAmount: (amount: number) => Promise<boolean>;
  refundLegacy: (transactionId: string, storeId: string) => Promise<boolean>;
  refund: (req: PaymentLinkRefundArgs) => Promise<unknown>;
  getRefundDetails: (
    referenceUUID: string,
  ) => Promise<GetRefundDetailsResponse>;
  getRefundReceiptDetails: (
    referenceUUID: string,
  ) => Promise<GetRefundReceiptDetailsResponse>;
}

const paymentLinkService: PaymentLinkServiceInterface = {
  getAvailablePaymentMethods,
  getExpirationOptions,
  createPaymentLink,
  getDetailsByUuid,
  getDetailsById,
  validateAmount,
  refundLegacy,
  refund,
  getRefundDetails,
  getRefundReceiptDetails,
};

export default paymentLinkService;
