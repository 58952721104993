import React from 'react';
import { Page } from '@nimbus-ds/patterns';
import { useTranslationWithPrefix } from 'commons/utils';
import RenderOnError from 'domains/Brazil/commons/components/RenderOnError';

export const defaultRefreshOnError = () => {};

type ContentOrErrorProps = {
  error: boolean;
  children: JSX.Element;
  refreshHandler?: () => void;
};

function ContentOrError({
  error,
  refreshHandler,
  children,
}: ContentOrErrorProps) {
  const { t } = useTranslationWithPrefix(
    'banking.identityValidation.loadingError',
  );

  return (
    <Page>
      <Page.Header title="" subtitle="" />
      <Page.Body>
        <RenderOnError
          title={t('title')}
          message=""
          label={t('tryAgain')}
          error={!!error}
          refreshHandler={refreshHandler ?? defaultRefreshOnError}
        >
          {children}
        </RenderOnError>
      </Page.Body>
    </Page>
  );
}

export default ContentOrError;
