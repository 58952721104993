import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import { track } from '@amplitude/analytics-browser';
import { Button, Link } from '@nimbus-ds/components';
import { useStoreInfo } from 'commons/hooks';
import {
  GetNotificationsResponse,
  NotificationLinkPresentationType,
  NotificationPresentationType,
  NotificationTypes,
} from 'commons/services';
import { NotificationStyleType } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';
import {
  dataDogLogger,
  DataDogLoggerActionTypes,
} from 'commons/utils/dataDogLogger';
import { NotificationContext } from '../NotificationProvider';
import AlertNotification from './AlertNotification';
import { notificationActionStyle } from './constants';
import ModalNotification from './ModalNotification';
import { NotificationComponentProps } from './types';

const isAlert = (presentationType?: NotificationPresentationType) =>
  presentationType === NotificationPresentationType.Alert;

const isModal = (presentationType?: NotificationPresentationType) =>
  presentationType === NotificationPresentationType.Modal;

const getComponent = ({ presentationType }: GetNotificationsResponse) =>
  isModal(presentationType) ? ModalNotification : AlertNotification;

const logDeleteRequestError = (
  error: unknown,
  context: Pick<
    GetNotificationsResponse,
    'id' | 'type' | 'deletable' | 'presentationType' | 'style'
  >,
) =>
  dataDogLogger.generate({
    actionMessage: `Error deleting notification ${context.id}`,
    actionOwner: 'Notification',
    actionType: DataDogLoggerActionTypes.Error,
    actionData: context,
    error,
  });

type ActionUrlArgs = Pick<
  GetNotificationsResponse,
  | 'linkName'
  | 'linkUrl'
  | 'linkTag'
  | 'hasInternalUrl'
  | 'linkPresentationType'
  | 'deleteOnClose'
  | 'deleteOnRefresh'
  | 'showDismissButton'
> & { linkStyle?: NotificationStyleType };

type NotificationRendererProps = GetNotificationsResponse & {
  removeNotification: (id: string) => Promise<any>;
  component?: React.VFC<NotificationComponentProps>;
};

function NotificationRenderer({
  title,
  id,
  type,
  style,
  message,
  linkName,
  linkUrl,
  linkTag,
  hasInternalUrl,
  linkPresentationType,
  deleteOnClose,
  deleteOnRefresh,
  metadata,
  messageCode,
  titleCode,
  removeNotification,
  deletable = true,
  presentationType = NotificationPresentationType.Alert,
  component: Component = AlertNotification,
  showDismissButton = true,
}: NotificationRendererProps) {
  const [showNotification, setShowNotification] = useState(true);
  const { storeInfo } = useStoreInfo();
  const { t } = useTranslationWithPrefix('');
  const history = useHistory();

  const deleteNotificationOnClose = useCallback(async () => {
    setShowNotification(false);

    try {
      if (!deleteOnClose) return;
      await removeNotification(id);
    } catch (error) {
      logDeleteRequestError(error, {
        id,
        deletable,
        type,
        presentationType,
        style,
      });
    }
  }, [
    deletable,
    id,
    presentationType,
    removeNotification,
    style,
    type,
    deleteOnClose,
  ]);

  const actionUrl = useCallback(
    ({
      linkName,
      linkUrl,
      linkTag,
      linkStyle,
      hasInternalUrl,
      linkPresentationType,
    }: ActionUrlArgs) => {
      if (!linkName) return;

      const isInternalUrl = linkUrl
        ? hasInternalUrl ?? linkUrl.includes(storeInfo.url)
        : false;

      if (isInternalUrl) {
        try {
          const url = new URL(linkUrl ?? '');
          linkUrl = `${url.pathname}${url.hash}`;
        } catch (_) {}
      }

      const handleLinkClick = () => {
        if (linkTag) track(linkTag);
        if (!linkUrl) return deleteNotificationOnClose();
        if (isInternalUrl)
          return history.push(linkUrl, { notificationIdOrigin: id });
        return window.open(linkUrl, '_blank');
      };

      if (
        linkPresentationType === NotificationLinkPresentationType.Button ||
        (!linkPresentationType && !isInternalUrl)
      ) {
        return (
          <Button
            appearance={linkStyle && notificationActionStyle[linkStyle]}
            onClick={handleLinkClick}
          >
            {linkName}
          </Button>
        );
      }

      return (
        <Link
          role="link"
          as="a"
          onClick={handleLinkClick}
          appearance={linkStyle && notificationActionStyle[linkStyle]}
        >
          {linkName}
        </Link>
      );
    },
    [history, deleteNotificationOnClose, storeInfo.url, id],
  );

  const notificationMessage = useMemo(() => {
    if (messageCode) {
      return t(messageCode, { ...metadata?.messageMetadata });
    }

    return t(message);
  }, [message, messageCode, metadata?.messageMetadata, t]);

  const notificationTitle = useMemo(() => {
    if (titleCode) {
      return t(titleCode, { ...metadata?.titleMetadata });
    }

    return t(title);
  }, [metadata?.titleMetadata, t, title, titleCode]);

  useEffect(() => {
    async function deleteNotificationOnRefresh() {
      try {
        if (!deleteOnRefresh) return;

        if (style === 'success' || style === 'error') {
          await removeNotification(id);
        }
      } catch (error) {
        logDeleteRequestError(error, {
          id,
          deletable,
          type,
          presentationType,
          style,
        });
      }
    }

    deleteNotificationOnRefresh();
  }, [
    deletable,
    id,
    presentationType,
    removeNotification,
    style,
    type,
    deleteOnRefresh,
  ]);

  return (
    <Component
      id={id}
      title={notificationTitle}
      message={notificationMessage}
      style={style}
      show={showNotification}
      onClose={
        presentationType === NotificationPresentationType.Modal ||
        showDismissButton
          ? deleteNotificationOnClose
          : undefined
      }
      action={actionUrl({
        linkName,
        linkUrl,
        linkTag,
        linkStyle: isModal(presentationType) ? style : undefined,
        linkPresentationType,
        hasInternalUrl,
        deleteOnClose,
        deleteOnRefresh,
        showDismissButton,
      })}
    />
  );
}

interface InterfaceNotification {
  listeningToTypes?: NotificationTypes[];
}

function Notification({
  listeningToTypes,
}: InterfaceNotification): JSX.Element {
  const { notifications, fetchNotifications, removeNotification } =
    useContext(NotificationContext);
  const [showNotifications, setShowNotifications] = useState<
    GetNotificationsResponse[]
  >([]);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  useEffect(() => {
    const filteredNotifications = notifications?.filter((notification) => {
      if (!listeningToTypes) {
        return true;
      } else {
        return listeningToTypes.includes(
          notification.type ?? NotificationTypes.NoType,
        );
      }
    });

    const notificationsToRender = filteredNotifications.filter(
      ({ presentationType }) => isAlert(presentationType),
    );
    const firstModalNotification = filteredNotifications.find(
      ({ presentationType }) => isModal(presentationType),
    );

    if (firstModalNotification) {
      notificationsToRender.push(firstModalNotification);
    }

    setShowNotifications(notificationsToRender);
  }, [listeningToTypes, notifications]);

  return (
    <>
      {showNotifications.map((notification) => {
        return (
          <NotificationRenderer
            key={notification.id}
            {...notification}
            removeNotification={removeNotification}
            component={getComponent(notification)}
          />
        );
      })}
    </>
  );
}

export default Notification;
