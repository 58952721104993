import React from 'react';
import { Box, Icon, Text } from '@nimbus-ds/components';
import { useIsMobile } from 'commons/hooks';

type IconTextProps = {
  icon: JSX.Element;
  text: string;
};

function TextMobile({ text }: { text: string }): JSX.Element {
  return (
    <Text color="neutral-textLow" fontSize="caption">
      {text}
    </Text>
  );
}

function TextDesktop({ text }: { text: string }): JSX.Element {
  return (
    <Text color="neutral-textLow" fontWeight="regular" fontSize="highlight">
      {text}
    </Text>
  );
}

function IconText({ icon, text }: IconTextProps): JSX.Element | null {
  const isMobile = useIsMobile();

  return (
    <Box display="flex" alignItems="center" gap="2">
      <Icon color="primary-interactive" source={icon} title={text} />
      {isMobile ? <TextMobile text={text} /> : <TextDesktop text={text} />}
    </Box>
  );
}

export default IconText;
