import React from 'react';
import { Box, Pagination, Table, Text } from '@nimbus-ds/components';
import { ExclamationTriangleIcon } from '@nimbus-ds/icons';
import { EmptyMessage } from '@nimbus-ds/patterns';
import { RestrictVisibility } from 'commons/components';
import { TransactionObjectInterface } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';
import HelpText from 'domains/Home/HelpText';
import { HomeTableRows } from './HomeTableRows';

interface DataTableDesktopInterface {
  transactions: TransactionObjectInterface[];
  pageNumber: number;
  totalPages: number;
  totalItems: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
  mock?: boolean;
  isLoading?: boolean;
}

function DataTableDesktop({
  transactions,
  pageNumber,
  totalPages,
  totalItems,
  itemsPerPage,
  onPageChange,
  mock,
  isLoading,
}: DataTableDesktopInterface): JSX.Element | null {
  const { t } = useTranslationWithPrefix('home.homeTable');

  const headers = [
    t('headers.orders'),
    t('headers.date'),
    t('headers.client'),
    t('headers.value'),
    t('headers.status'),
  ].map((header, index) => {
    return (
      <Table.Cell backgroundColor="neutral-surface" key={index}>
        <Text fontWeight="medium" color="neutral-textHigh">
          {header}
        </Text>
      </Table.Cell>
    );
  });

  const firstItemCount = itemsPerPage * (pageNumber - 1) + 1;
  const lastItemCount = itemsPerPage * (pageNumber - 1) + transactions.length;

  const Footer = (
    <Table.Head>
      <Table.Cell width="1200px">
        <Box display="flex" justifyContent="space-between" gap="4">
          <Box>
            <Text>
              {t(
                `showingOrders${
                  lastItemCount - firstItemCount > 1 ? '' : '_one'
                }`,
                {
                  first: firstItemCount,
                  last: lastItemCount,
                  total: totalItems,
                },
              )}
            </Text>
          </Box>
          {totalPages > 1 && (
            <Box>
              <Pagination
                pageCount={totalPages}
                activePage={pageNumber}
                onPageChange={onPageChange}
              />
            </Box>
          )}
        </Box>
      </Table.Cell>
    </Table.Head>
  );

  return (
    <>
      {transactions.length || isLoading ? (
        <>
          <Table>
            <Table.Head>{headers}</Table.Head>
            {isLoading ? (
              <HomeTableRows.Skeleton />
            ) : (
              <Table.Body>
                <HomeTableRows transactions={transactions} mock={mock} />
              </Table.Body>
            )}
          </Table>
          {!isLoading && Footer}
          <RestrictVisibility view="helpText">
            <HelpText />
          </RestrictVisibility>
        </>
      ) : (
        <EmptyMessage
          icon={<ExclamationTriangleIcon size={32} />}
          title={t('emptySearch.title')}
          text={t('emptySearch.text')}
        />
      )}
    </>
  );
}

export default DataTableDesktop;
