import axios from 'App/axios';
import {
  DiscountType,
  InstallmentsFeeResponsible,
  InstallmentV2Type,
  MoneyObjectInterface,
  PaymentMethodsType,
  SettlementPeriodType,
} from 'commons/types';
import {
  GetIdValidationResponse,
  GetInstallmentsV2Response,
  GetPreferencesResponse,
  Plan,
} from './configurationServiceResponses';

const removeOneInstallmentWithoutInterest = <
  T extends { withoutInterest: Array<any> },
>(
  data: T,
): T => {
  const newData = { ...data };
  const index = newData.withoutInterest.findIndex(
    (elem) => elem.quantity === 1,
  );
  if (index > -1) {
    newData.withoutInterest.splice(index, 1);
  }
  return newData;
};

const getInstallmentsV2 = async () => {
  const response = await axios.get<GetInstallmentsV2Response>(
    '/admin/v2/installments',
  );
  return removeOneInstallmentWithoutInterest<GetInstallmentsV2Response>(
    response.data,
  );
};

type PatchInstallmentsV2Type = (data: {
  planAhora?: Partial<InstallmentV2Type>[];
  withoutInterest?: Partial<InstallmentV2Type>[];
  withInterest?: Partial<InstallmentV2Type>[];
  minInstallmentValue?: MoneyObjectInterface;
}) => Promise<void>;

const patchInstallmentsV2: PatchInstallmentsV2Type = async (data) => {
  const response = await axios.patch('/admin/v2/installments', {
    plan_ahora: data.planAhora,
    without_interest: data.withoutInterest,
    with_interest: data.withInterest,
    min_installment_value: data.minInstallmentValue,
  });
  return response.data;
};

type PatchInstallmentsResponsibleType = (
  installmentsResponsible: InstallmentsFeeResponsible,
) => Promise<void>;

const patchInstallmentsResponsible: PatchInstallmentsResponsibleType = async (
  installmentsResponsible,
) => {
  const response = await axios.patch('/admin/installments-responsible', {
    installments_responsible: installmentsResponsible,
  });
  return response.data;
};

type GetPreferencesType = () => Promise<GetPreferencesResponse>;

const getPreferences: GetPreferencesType = async () => {
  const response = await axios.get(`/admin/preferences`);
  return response.data;
};

type PatchPreferencesType = (
  actives: Partial<PaymentMethodsType[]>,
  inactives: Partial<PaymentMethodsType[]>,
  discounts?: DiscountType[],
) => Promise<number>;

const patchPreferences: PatchPreferencesType = async (
  actives: Partial<PaymentMethodsType[]>,
  inactives: Partial<PaymentMethodsType[]>,
  discounts?: DiscountType[],
) => {
  const response = await axios.patch(`/admin/preferences`, {
    active_payment_methods: actives,
    inactive_payment_methods: inactives,
    discounts,
  });
  return response.status;
};

type GetIdValidationType = () => Promise<GetIdValidationResponse>;

const getIdValidation: GetIdValidationType = async () => {
  const response = await axios.get(`/admin/validation`);
  return response.data;
};

const getStorePlan = async (): Promise<Plan> => {
  const response = await axios.get<Plan>('/admin/plan');
  return response.data;
};

type GetSettlementPeriodType = () => Promise<SettlementPeriodType>;

const getSettlementPeriod = async (): Promise<SettlementPeriodType> => {
  const response =
    await axios.get<GetPreferencesResponse>('/admin/preferences');
  return response.data.settlementPeriod;
};

type PutSettlementPeriodType = (
  settlementPeriod: SettlementPeriodType,
) => Promise<void>;

const putSettlementPeriod: PutSettlementPeriodType = async (
  settlementPeriod: SettlementPeriodType,
) => {
  const response = await axios.put('/admin/settlement-period', {
    settlement_period: settlementPeriod,
  });
  return response.data;
};

interface ConfigurationServicesInterface {
  getInstallmentsV2: () => Promise<GetInstallmentsV2Response>;
  patchInstallmentsV2: PatchInstallmentsV2Type;
  getPreferences: GetPreferencesType;
  patchPreferences: PatchPreferencesType;
  getIdValidation: GetIdValidationType;
  getStorePlan: typeof getStorePlan;
  getSettlementPeriod: GetSettlementPeriodType;
  putSettlementPeriod: PutSettlementPeriodType;
  patchInstallmentsResponsible: PatchInstallmentsResponsibleType;
}

const configurationService: ConfigurationServicesInterface = {
  getInstallmentsV2,
  patchInstallmentsV2,
  getPreferences,
  patchPreferences,
  getIdValidation,
  getStorePlan,
  getSettlementPeriod,
  putSettlementPeriod,
  patchInstallmentsResponsible,
};

export default configurationService;
