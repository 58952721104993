import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Box, ToastProvider, useToast } from '@nimbus-ds/components';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import nexo from 'App/nexoClient';
import { ROUTES } from 'App/Routes/constants';
import { useIsMobile } from 'commons/hooks';
import transactionsService from 'commons/services/dashboardService/transactions.service';
import { OrderType } from 'commons/types/OrderType.enum';
import { useTranslationWithPrefix } from 'commons/utils';
import NuvemPagoCta from 'domains/Brazil/commons/components/NuvemPagoCta';
import { TransactionDetailsType } from 'domains/Brazil/commons/types/TransactionsDetailsType';
import { isPix } from 'domains/Brazil/commons/utils/paymentMethod';
import {
  NotificationBRContext,
  NotificationType,
} from 'domains/Brazil/Notifications/Notification.context';
import { AlertRefund } from 'domains/Brazil/Refund/components/AlertRefund/AlertRefund';
import CustomerCard from './components/CustomerCard';
import HeaderBox from './components/HeaderBox';
import PaymentCard from './components/PaymentCard';
import SummaryCard from './components/SummaryCard';

interface TransactionDetailParams {
  transactionId: string;
}

interface CustomAlertProps {
  title: string;
  message: string;
  linkTitle?: string;
  linkUrl?: string;
  appearance:
    | 'primary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'neutral'
    | undefined;
  testid: string;
}

function TransactionDetail(): JSX.Element {
  const { t: transactionT } = useTranslationWithPrefix('transactions.details');
  const { t: navT } = useTranslationWithPrefix('navigation');
  const { t: refundT } = useTranslationWithPrefix('');
  const { transactionId } = useParams<TransactionDetailParams>();
  const [details, setDetails] = useState<TransactionDetailsType | undefined>();
  const isMobile = useIsMobile();
  const { addToast } = useToast();
  const [isShowAlert, setIsShowAlert] = useState(false);
  const notificationContext = useContext(NotificationBRContext);

  // eslint-disable-next-line max-len
  const [customAlertProps, setCustomAlertProps] = useState<CustomAlertProps>({
    title: '',
    message: '',
    linkTitle: '',
    appearance: undefined,
    testid: '',
  });

  const handleConsumeRefundSuccessNotification = useCallback(
    (notificationId: string, partial?: boolean) => {
      if (!details) return;

      notificationContext.removeNotification(notificationId);

      if (isPix(details.payment.paymentMethod)) {
        if (partial) {
          setCustomAlertProps({
            title: refundT('refund.alert.partial-success-pix.title'),
            message: refundT('refund.alert.partial-success-pix.message'),
            appearance: 'success',
            testid: 'Transaction-Refund-pix-Success-Alert',
          });
        } else {
          setCustomAlertProps({
            title: refundT('refund.alert.success-pix.title'),
            message: refundT('refund.alert.success-pix.message'),
            appearance: 'success',
            testid: 'Transaction-Refund-pix-Success-Alert',
          });
        }
      } else {
        if (partial) {
          setCustomAlertProps({
            title: refundT('refund.alert.partial-success.title'),
            message: refundT('refund.alert.partial-success.message'),
            appearance: 'success',
            testid: 'Transaction-Partial-Refund-Success-Alert',
          });
        } else {
          setCustomAlertProps({
            title: refundT('refund.alert.success.title'),
            message: refundT('refund.alert.success.message'),
            appearance: 'success',
            testid: 'Transaction-Refund-Success-Alert',
          });
        }
      }
      setIsShowAlert(true);
    },
    [details, notificationContext, refundT],
  );

  useEffect(() => {
    navigateHeader(nexo, { goTo: ROUTES.DASHBOARD, text: navT('back') });

    const fetchDetails = async () => {
      try {
        if (!details) {
          const txnData = await transactionsService.getDetails(transactionId);
          setDetails(txnData);
        }

        const refundSuccessNotifications =
          notificationContext.findNotificationByType(
            NotificationType.RefundSuccess,
          );
        const partialRefundSuccessNotifications =
          notificationContext.findNotificationByType(
            NotificationType.PartialRefundSuccess,
          );

        if (partialRefundSuccessNotifications.length) {
          handleConsumeRefundSuccessNotification(
            partialRefundSuccessNotifications[0].id,
            true,
          );
        } else if (refundSuccessNotifications.length) {
          handleConsumeRefundSuccessNotification(
            refundSuccessNotifications[0].id,
            false,
          );
        }
      } catch (error) {
        addToast({
          type: 'danger',
          text: transactionT('error.getDetailsFailure'),
          duration: 4000,
          id: 'transaction-details-failure-toast',
        });
      }
    };

    fetchDetails();
  }, [
    transactionId,
    addToast,
    transactionT,
    navT,
    refundT,
    notificationContext,
    handleConsumeRefundSuccessNotification,
    details,
  ]);

  const renderCta = () => (
    <NuvemPagoCta
      href={
        'https://atendimento.nuvemshop.com.br/pt_BR/gestao-dos-pagamentos/como-consultar-os-pagamentos-atraves-do-painel-nuvem-pago'
      }
      title={transactionT('cta')}
      trackingTag="nuvempago_link_detail_transaction_can_link_click"
    />
  );

  const renderAlert = () => (
    <AlertRefund
      isShowAlert={isShowAlert}
      title={customAlertProps.title}
      message={customAlertProps.message}
      linkTitle={customAlertProps.linkTitle}
      linkUrl={customAlertProps?.linkUrl}
      appearance={customAlertProps.appearance}
      handleAlertCloseClick={handleAlertCloseClick}
      testid={customAlertProps.testid}
    />
  );

  const handleAlertCloseClick = useCallback(() => {
    setIsShowAlert(false);
  }, []);

  const renderDeskTopGrid = () => (
    <Box width={'100%'} padding={'4'} backgroundColor="neutral-surface">
      <Box
        display="grid"
        gridTemplateAreas='"margin-l hd margin-r"
                      "margin-l cards margin-r"
                      "margin-l ft margin-r"'
        gridTemplateColumns={'[margin-l] [cards] 3fr [margin-r]'}
        alignItems={'flex-start'}
        justifyContent={'center'}
        flexWrap={'wrap'}
        flexDirection={'column'}
      >
        <Box display="grid" gridArea={'hd'} marginBottom={'2'}>
          {details ? <HeaderBox details={details} /> : <HeaderBox.Skeleton />}
          {renderAlert()}
        </Box>
        <Box
          display={'grid'}
          gridArea={'cards'}
          gap={'8'}
          gridTemplateAreas='"lcard rcard"'
          gridTemplateColumns={'[lcard] 2fr [rcard] 1fr'}
        >
          <Box display="grid" gridArea={'lcard'} gap={'5'}>
            {details ? (
              <>
                <SummaryCard
                  status={details.status}
                  summary={details.payment}
                />
                <PaymentCard
                  summary={{
                    ...details.payment,
                    id: details.id,
                    orderId: details.orderId,
                    orderStatus: details.status,
                    orderType: details.paymentLink
                      ? OrderType.PaymentLink
                      : OrderType.Checkout,
                  }}
                />
              </>
            ) : (
              <>
                <SummaryCard.Skeleton />
                <PaymentCard.Skeleton />
              </>
            )}
          </Box>
          <Box display={'grid'} gridArea={'rcard'}>
            <Box display="flex" flexDirection={'column'} gap={'5'}>
              {details ? (
                <CustomerCard customer={details.customer} />
              ) : (
                <CustomerCard.Skeleton />
              )}
            </Box>
          </Box>
        </Box>
        <Box display="grid" gridArea={'ft'} marginTop={'6'}>
          {details && renderCta()}
        </Box>
      </Box>
    </Box>
  );

  const renderMobileGrid = () => (
    <Box padding={'4'} backgroundColor="neutral-background">
      <Box display="grid" gap="4">
        {details ? (
          <>
            <HeaderBox details={details} />
            <AlertRefund
              isShowAlert={isShowAlert}
              title={customAlertProps.title}
              message={customAlertProps.message}
              linkTitle={customAlertProps.linkTitle}
              linkUrl={customAlertProps?.linkUrl}
              appearance={customAlertProps.appearance}
              handleAlertCloseClick={handleAlertCloseClick}
              testid={customAlertProps.testid}
            />
            <SummaryCard status={details.status} summary={details.payment} />
            <PaymentCard
              summary={{
                ...details.payment,
                id: details.id,
                orderId: details.orderId,
                orderStatus: details.status,
              }}
            />
            <CustomerCard customer={details.customer} />
          </>
        ) : (
          <>
            <HeaderBox.Skeleton />
            <SummaryCard.Skeleton />
            <PaymentCard.Skeleton />
            <CustomerCard.Skeleton />
          </>
        )}
        {details && renderCta()}
      </Box>
    </Box>
  );

  return (
    <ToastProvider>
      {isMobile ? renderMobileGrid() : renderDeskTopGrid()}
    </ToastProvider>
  );
}
export default TransactionDetail;
