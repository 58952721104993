import React from 'react';
import { AspectRatio, Thumbnail } from '@nimbus-ds/components';

interface ThumbnailFileSkeletonProps {
  aspectRatio: AspectRatio;
}

function ThumbnailFileUploaderSkeleton({
  aspectRatio,
}: ThumbnailFileSkeletonProps): JSX.Element {
  return <Thumbnail.Skeleton aspectRatio={aspectRatio} width="100%" />;
}

export default ThumbnailFileUploaderSkeleton;
