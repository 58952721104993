import React, { useState } from 'react';
import {
  Alert,
  Box,
  Card,
  Icon,
  Radio,
  Tag,
  Text,
  Title,
} from '@nimbus-ds/components';
import {
  EyeIcon,
  FileIcon,
  SizeWidthIcon,
  StoreIcon,
  SunIcon,
  UserIcon,
} from '@nimbus-ds/icons';
import { Page } from '@nimbus-ds/patterns';
import AppLayout from 'App/components/AppLayout';
import { ROUTES } from 'App/Routes/constants';
import { useIsMobile, useToast } from 'commons/hooks';
import bankingService, {
  Document,
  DocumentSide,
  DocumentType,
} from 'commons/services/bankingService/bankingService';
import { useTranslationWithPrefix } from 'commons/utils';
import { RecipientType } from 'domains/Brazil/commons/types/RecipientType.enum';
import ContentOrError from '../../components/ContentOrError';
import FooterButtons from '../../components/FooterButtons';
import ThumbnailFileUploader from '../../components/ThumbnailFileUploader';
import { BankingIdentityValidationPageProps } from '../../IdentityValidation';

const DocumentOptions = [{ name: 'RG' }, { name: 'CNH' }];

type FlexColumnProps = React.ComponentProps<typeof Box> & {
  children: React.ReactNode;
};

const FlexColumn = ({ children, ...props }: FlexColumnProps) => (
  <Box display="flex" flexDirection="column" {...props}>
    {children}
  </Box>
);

type IconTextRowProps = {
  icon: React.ReactNode;
  text: string;
};

const IconTextRow = ({ icon, text }: IconTextRowProps) => (
  <Box display="flex" alignItems="center" gap="2">
    <Icon color="primary-interactive" source={icon} />
    <Text color="neutral-textLow" fontWeight="medium" fontSize="caption">
      {text}
    </Text>
  </Box>
);

type DocumentOptionListProps = {
  selectedDocument: string;
  handleDocumentChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  t: any;
};

const DocumentOptionList = ({
  selectedDocument,
  handleDocumentChange,
  t,
}: DocumentOptionListProps) => (
  <FlexColumn gap="4">
    <Title as="h4">{t('documentType.title')}</Title>
    {DocumentOptions.map((documentOption) => (
      <Radio
        name="documentType"
        onChange={(event) => handleDocumentChange(event)}
        key={documentOption.name}
        checked={selectedDocument === documentOption.name}
        label={documentOption.name}
        value={documentOption.name}
        id={documentOption.name}
      />
    ))}
  </FlexColumn>
);

type SelectDocumentStepPageProps = BankingIdentityValidationPageProps & {
  recipientDocument: string | undefined;
  recipientType: RecipientType | undefined;
  onSelectDocument: React.Dispatch<React.SetStateAction<DocumentType | null>>;
};

export function SelectDocumentStepPage({
  backStep,
  nextStep,
  onSelectDocument,
  recipientDocument,
  recipientType,
}: SelectDocumentStepPageProps): JSX.Element | null {
  const isMobile = useIsMobile();
  const { t } = useTranslationWithPrefix(
    'banking.identityValidation.selectDocumentsPage',
  );
  const [selectedDocument, setSelectedDocument] = useState('');
  const [documentFront, setDocumentFront] = useState<File | null>(null);
  const [documentBack, setDocumentBack] = useState<File | null>(null);
  const [fileSizeError, setFileSizeError] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { showToastSuccess, showToastError } = useToast();

  const isIndividual = recipientType === 'INDIVIDUAL' ? true : false;

  const [resetKey, setResetKey] = useState(false);

  const handleDocumentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDocumentFront(null);
    setDocumentBack(null);
    setSelectedDocument(event.target.value);
    setResetKey((prevKey) => !prevKey);
    onSelectDocument(event.target.value as DocumentType);
  };

  const handleError = (hasError: boolean, text: string) => {
    showToastError(text);
    setFileSizeError(hasError);
  };

  const documentInstructions = [
    {
      icon: <SizeWidthIcon />,
      text: t('instructionsContainer.itemWidth'),
    },
    {
      icon: <EyeIcon />,
      text: t('instructionsContainer.itemEye'),
    },
    {
      icon: <SunIcon />,
      text: t('instructionsContainer.itemSun'),
    },
    {
      icon: <FileIcon />,
      text: t('instructionsContainer.itemFile'),
    },
  ];

  const thumbnailFiles = [{ helperText: 'Frente' }, { helperText: 'Verso' }];
  const MAX_FILE_10_MB = 10 * 1024 * 1024; // 10MB em bytes

  const onSelectFileHandler = function (documentSide: string, file: File) {
    showToastSuccess(t('toast.success'));
    setFileSizeError(false);
    if (documentSide === 'Frente') {
      setDocumentFront(file);
    } else {
      setDocumentBack(file);
    }
  };

  async function uploadFile() {
    setLoading(true);

    if (!documentFront || !documentBack) return;

    const documentFrontExtension =
      bankingService.extractFileExtension(documentFront);
    const documentBackExtension =
      bankingService.extractFileExtension(documentBack);

    if (!documentFrontExtension || !documentBackExtension) {
      setError(true);
      return;
    }

    const frontDocument: Document = {
      documentType:
        selectedDocument === 'RG' ? DocumentType.RG : DocumentType.CNH,
      documentSide: DocumentSide.FRONT,
    };

    const backDocument: Document = {
      documentType:
        selectedDocument === 'RG' ? DocumentType.RG : DocumentType.CNH,
      documentSide: DocumentSide.BACK,
    };

    try {
      await bankingService.addAccountDocument({
        document: frontDocument,
        file: documentFront,
        recipientType,
      });

      await bankingService.addAccountDocument({
        document: backDocument,
        file: documentBack,
        recipientType,
      });

      nextStep();
    } catch (error) {
      showToastError(t('toast.error'));
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  const handlePrimaryButton = async () => {
    await uploadFile();
  };

  const refreshOnError = () => {
    setError(false);
  };

  return (
    <AppLayout
      title=""
      backLinkText={isMobile ? '' : undefined}
      backLinkPath={ROUTES.DASHBOARD}
    >
      <ContentOrError error={error} refreshHandler={refreshOnError}>
        <Box
          data-testid="select-document-step-page"
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          padding="none"
        >
          {recipientDocument && (
            <Page maxWidth="800px">
              <Page.Header paddingLeft="none" title={t('title')} />
              <Tag appearance="neutral">{t('step')}</Tag>
              <FlexColumn gap="4" padding="none">
                <FlexColumn marginTop="5" gap="4">
                  <Text
                    fontSize="highlight"
                    fontWeight="medium"
                    color="neutral-textLow"
                  >
                    {t('documentRelatedWithStore', {
                      document: isIndividual ? ' CPF ' : ' CNPJ ',
                    })}
                  </Text>
                  <IconTextRow
                    icon={isIndividual ? <UserIcon /> : <StoreIcon />}
                    text={
                      isIndividual
                        ? `CPF ${recipientDocument}`
                        : `CNPJ ${recipientDocument}`
                    }
                  />
                </FlexColumn>
                <Box display="flex" gap="4">
                  <Card>
                    <DocumentOptionList
                      selectedDocument={selectedDocument}
                      handleDocumentChange={handleDocumentChange}
                      t={t}
                    />
                  </Card>
                </Box>
                {selectedDocument && (
                  <Card>
                    <Title as="h4">
                      {t('instructionsContainer.title', {
                        document: selectedDocument,
                      })}
                    </Title>

                    {documentInstructions.map((instruction, index) => (
                      <IconTextRow
                        key={index}
                        icon={instruction.icon}
                        text={instruction.text}
                      />
                    ))}

                    {fileSizeError && (
                      <Box flex="1">
                        <Alert
                          appearance="danger"
                          title={t('alert.errorTitle')}
                        >
                          <Text>{t('textFileSizeError')}</Text>
                        </Alert>
                      </Box>
                    )}

                    <Box display="flex" gap="4">
                      {thumbnailFiles.map((fileItem, index) => (
                        <Box flex="1" key={index} gap="2">
                          <ThumbnailFileUploader
                            accept="image/jpeg,image/png"
                            ariaLabel={fileItem.helperText}
                            fileName={fileItem.helperText}
                            helperText={fileItem.helperText}
                            maxFileSize={MAX_FILE_10_MB}
                            onSelectFile={(event) =>
                              onSelectFileHandler(fileItem.helperText, event)
                            }
                            onDelete={() =>
                              fileItem.helperText === 'Frente'
                                ? setDocumentFront(null)
                                : setDocumentBack(null)
                            }
                            onError={() => handleError(true, t('toast.error'))}
                            aspectRatio="16/9"
                            resetKey={resetKey}
                          />
                        </Box>
                      ))}
                    </Box>
                  </Card>
                )}
              </FlexColumn>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                gap="2"
                marginTop="4"
              >
                <FooterButtons
                  primaryButtonHandler={handlePrimaryButton}
                  secondaryButtonHandler={backStep}
                  primaryButtonLabel={t('buttons.primary')}
                  secondaryButtonLabel={t('buttons.secondary')}
                  isSubmitting={isLoading}
                  isDisabled={
                    !selectedDocument || !documentFront || !documentBack
                  }
                />
              </Box>
            </Page>
          )}
        </Box>
      </ContentOrError>
    </AppLayout>
  );
}

export default SelectDocumentStepPage;
