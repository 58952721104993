import appAxios from 'App/axios';
import axios from 'axios';

export interface PreSignedUrl {
  url: string;
  fields: Record<string, string>;
}

export enum DocumentType {
  CNH = 'CNH',
  RG = 'RG',
  SELFIE = 'SELFIE',
}

export enum DocumentSide {
  FRONT = 'FRONT',
  BACK = 'BACK',
}

export type Document = {
  documentType: DocumentType;
  documentSide: DocumentSide;
};

interface AddAccountDocumentArgs {
  file: File;
  document: Document;
  recipientType?: string;
}

const fipHost = `${process.env.REACT_APP_BANKING_GATEWAY_URL}/identity`;

function extractFileExtension(file: File): string {
  const extension = file.type.split('/').pop();

  if (!extension) {
    throw new Error('Invalid file extension');
  }

  return extension;
}

async function uploadFileToS3(
  fileStream: File,
  { url, fields }: PreSignedUrl,
): Promise<void> {
  try {
    const formData = new FormData();
    Object.entries(fields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('file', fileStream);

    await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    throw error;
  }
}

async function obtainPresignedUrlForUpload(
  fileExtension: string,
): Promise<PreSignedUrl> {
  try {
    const response = await appAxios.post(`${fipHost}/v1/documents/url`, {
      extension: fileExtension,
    });

    const { url, fields } = response.data;
    return { url, fields };
  } catch (error) {
    throw error;
  }
}

async function addDocumentReference(
  reference: string,
  fileExtension: string,
  document: Document,
  recipientType?: string,
) {
  const payload = {
    personType: recipientType === 'COMPANY' ? 'LEGAL_PERSON' : 'NATURAL_PERSON',
    document: {
      type: document.documentType,
      references: [
        {
          extension: fileExtension,
          side: document.documentSide,
          correlation_id: reference,
        },
      ],
    },
  };
  await appAxios.put(`${fipHost}/v1/documents/references`, payload);
}

async function addAccountDocument({
  file,
  document,
  recipientType,
}: AddAccountDocumentArgs): Promise<void> {
  const fileExtension = extractFileExtension(file);

  const { url, fields } = await obtainPresignedUrlForUpload(fileExtension);

  await uploadFileToS3(file, { url, fields });

  const reference = `${url}${fields.key}`;
  await addDocumentReference(reference, fileExtension, document, recipientType);
}

export default {
  obtainPresignedUrlForUpload,
  uploadFileToS3,
  extractFileExtension,
  addAccountDocument,
  addDocumentReference,
};
