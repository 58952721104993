import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import {
  Event,
  GetRefundReceiptDetailsResponse,
} from 'commons/services/refundReceiptService';
import refundReceiptService from 'commons/services/refundReceiptService/refundReceiptService';
import { OrderType } from 'commons/types/OrderType.enum';
import RefundReceipt from './RefundReceipt';

interface RefundReceiptDetailPathParams {
  transactionId: string;
  orderId: string;
}

function TransactionRefundReceipt(): JSX.Element {
  const { transactionId, orderId } = useParams<RefundReceiptDetailPathParams>();
  const { search } = useLocation();
  const useV2 = new URLSearchParams(search).get('v') === '2';

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [details, setDetails] = useState<GetRefundReceiptDetailsResponse>();

  useEffect(() => {
    const fetchData = async () => {
      /**
       * In v2, the only path param is the transactionId (the 1st parameter),
       * which is the order ID from Nuvemshop. We could also check if the
       * orderId is undefined, but since we have 2 routes to the same page,
       * there is no way to avoid some 404 errors if the store admins try to
       * build a URL by themselves.
       *
       * @todo Remove this check and use only the transactionId when
       * the v2 rollout is complete, as it will be the only path parameter.
       */
      const targetId = useV2 ? transactionId : orderId;

      try {
        const details =
          await refundReceiptService.getRefundReceiptDetails(targetId);
        if (Array.isArray(details.events)) {
          details.events.sort(
            (a: Event, b: Event) =>
              new Date(b.occurredAt).valueOf() -
              new Date(a.occurredAt).valueOf(),
          );
        }

        details.transactionId ??= useV2 ? targetId : transactionId;
        details.orderId ??= useV2 ? targetId : orderId;

        setDetails(details);
      } catch (error: any) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [orderId, transactionId, useV2]);

  return (
    <RefundReceipt
      details={details}
      isError={isError}
      isLoading={isLoading}
      receiptPaymentMethod={OrderType.Checkout}
    />
  );
}
export default TransactionRefundReceipt;
