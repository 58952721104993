import React from 'react';
import { Box, Button, Spinner } from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';

export interface formButtons {
  primaryButtonHandler: () => void;
  secondaryButtonHandler: () => void;
  isSubmitting?: boolean;
  isDisabled?: boolean;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
}

function FooterButtons({
  primaryButtonHandler,
  secondaryButtonHandler,
  isSubmitting = false,
  isDisabled = false,
  primaryButtonLabel,
  secondaryButtonLabel,
}: formButtons): JSX.Element {
  const { t } = useTranslationWithPrefix(
    'banking.identityValidation.footerButtons',
  );
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      gap="4"
      paddingY="4"
    >
      <Button
        data-testid="FooterButtonsSecondaryButton"
        onClick={secondaryButtonHandler}
      >
        {secondaryButtonLabel || t('defaultSecondaryLabel')}
      </Button>
      <Button
        appearance={'primary'}
        onClick={primaryButtonHandler}
        disabled={isDisabled || isSubmitting}
        data-testid="FooterButtonsPrimaryButton"
      >
        {isSubmitting && <Spinner size="small" />}
        {primaryButtonLabel || t('defaultPrimaryLabel')}
      </Button>
    </Box>
  );
}

function FooterButtonsSkeleton(): JSX.Element {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      width="100%"
      gridGap="2"
    >
      <Button.Skeleton />
      <Button.Skeleton />
    </Box>
  );
}

FooterButtons.Skeleton = FooterButtonsSkeleton;

export default FooterButtons;
