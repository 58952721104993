import { Country } from 'App/i18n/enums';
import useStoreInfo from './useStoreInfo';

export const useCountry = () => {
  const { storeInfo } = useStoreInfo();

  return {
    storeCountry: storeInfo.country as Country,
    isMexico: storeInfo.country === Country.MEXICO,
  };
};
