import React, { useEffect } from 'react';
import { Box, Skeleton, Text, Title } from '@nimbus-ds/components';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import AppLayout from 'App/components/AppLayout';
import nexo from 'App/nexoClient';
import { ROUTES } from 'App/Routes/constants';
import { useIsMobile } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';
import FooterButtons from '../../components/FooterButtons';

function IntroductionStepPageSkeleton(): JSX.Element | null {
  const { t: navT } = useTranslationWithPrefix('navigation');
  const isMobile = useIsMobile();

  useEffect(() => {
    navigateHeader(nexo, { goTo: ROUTES.DASHBOARD, text: navT('back') });
  }, [navT]);

  return (
    <Box data-testid="introduction-step-page-skeleton">
      <AppLayout
        title=""
        subtitle=""
        backLinkText={isMobile ? '' : undefined}
        backLinkPath={ROUTES.DASHBOARD}
      >
        <Box display="grid" gridGap="4" paddingY="4">
          <Title.Skeleton width="100%" />
          <Text.Skeleton width="100%" />
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent={isMobile ? 'flex-start' : 'center'}
          alignItems="center"
          alignSelf="stretch"
          height="100vh"
          padding={isMobile ? 'none' : '4'}
        >
          <Box
            maxWidth="800px"
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            padding={isMobile ? 'none' : '4'}
          >
            <Box
              width="100%"
              display="grid"
              gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'}
              gridGap="4"
            >
              <Box paddingX={isMobile ? 'none' : '4'}>
                <Skeleton width="100%" height="240px" borderRadius="8px" />
              </Box>

              <Box display="grid" gridGap={isMobile ? '4' : '2'} paddingY="4">
                <Title.Skeleton width="100%" />
                <Text.Skeleton width="100%" />
                <Text.Skeleton width="100%" />
                <Text.Skeleton width="100%" />
                <FooterButtons.Skeleton />
              </Box>
            </Box>
          </Box>
        </Box>
      </AppLayout>
    </Box>
  );
}

export default IntroductionStepPageSkeleton;
