import React from 'react';

const UserIdentityIcon: React.FC = () => (
  <svg
    width="1em"
    height="1em"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M7.6 6.60645C5.8897 6.60645 4.50323 5.12755 4.50323 3.30323C4.50323 1.4789 5.8897 0 7.6 0C9.31031 0 10.6968 1.4789 10.6968 3.30323C10.6968 5.12755 9.31031 6.60645 7.6 6.60645ZM7.6 5.31613C6.67951 5.31613 5.79355 4.49463 5.79355 3.30323C5.79355 2.11182 6.67951 1.29032 7.6 1.29032C8.5205 1.29032 9.40646 2.11182 9.40646 3.30323C9.40646 4.49463 8.5205 5.31613 7.6 5.31613ZM11.9097 12.6707C11.9097 12.4879 11.8924 12.3069 11.8588 12.129H13.1404C13.1627 12.3062 13.1742 12.4869 13.1742 12.6707L13.1742 16H11.9097V12.6707ZM7.5871 9.09677H7.5872V7.83226H7.5871C4.6025 7.83226 2 9.90534 2 12.6707V16L3.26452 16L3.26452 12.6707C3.26452 10.79 5.09873 9.09678 7.5871 9.09677ZM8.01946 8C8.01946 7.66863 8.28808 7.4 8.61946 7.4H13.7807C14.1121 7.4 14.3807 7.66863 14.3807 8V11.0968C14.3807 11.4281 14.1121 11.6968 13.7807 11.6968H8.61946C8.28808 11.6968 8.01946 11.4281 8.01946 11.0968V8ZM9.21946 8.6V10.4968H13.1807V8.6H9.21946ZM9.65178 9.03226H10.684V10.0645H9.65178V9.03226Z"
    />
  </svg>
);

export default UserIdentityIcon;
